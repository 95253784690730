import { React, moment, bind } from '../../../Imports';
import { VideoEventCommentResponse, VideoEventResponse } from '$Generated/api';
import { IVideoEventServiceInjectedProps } from '../../../state/VideoEventFreezerService';
import { Button, Card, TextField, Dialog, DialogActions, DialogContent, DialogTitle } from '../../../MaterialUIComponents';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTrash } from '@fortawesome/pro-solid-svg-icons';
import * as DateFormatter from '../../Shared/DateFormatter';
import * as GeotabSecurity from '../../../utilities/geotabSecurity';
import { RolesEnum, canEditByRole } from '../../../externals/VerifyRole';

interface IEventCommentsBaseProps {
    event: VideoEventResponse;
    updateVideoEventHistory?: () => void;
}

type IEventCommentsProps = IEventCommentsBaseProps & IVideoEventServiceInjectedProps;

interface IEventCommentsState {
    newComment: string;
    editComment: string | undefined;
    editingId: number | undefined;
    deleteComment: boolean;
    deleteId: number | undefined;
    canEdit: boolean;
}

const styles = require('./EventComments.scss') as {
    main: string;
    sectionTitle: string;
    card: string;
    cardBody: string;
    commentContainer: string;
    comment: string;
    content: string;
    edit: string;
    details: string;
    controls: string;
    controlLeft: string;
    controlRight: string;
    controlButton: string;
    new: string;
    comInput: string;
    save: string;
    dialogContent: string;
    actionButton: string;
    disabledByRole: string;
    disabled: string;
};

export class EventComments extends React.Component<IEventCommentsProps, IEventCommentsState> {
    state = {
        newComment: '',
        editComment: '',
        editingId: undefined,
        deleteComment: false,
        deleteId: undefined,
        canEdit: false,
    };

    async componentDidMount(): Promise<void> {
        const canEdit = canEditByRole([RolesEnum.videoEventEdit, RolesEnum.videoRecallEdit]);
        this.setState({ canEdit: canEdit });

        if (this.props.event.id) {
            await this.props.videoEvents.getVideoEventComments(this.props.event.id);
        }
    }

    @bind
    handleEditChange(event: any) {
        this.setState({
            editComment: event.target.value,
        });
    }

    @bind
    handleNewChange(event: any) {
        this.setState({
            newComment: event.target.value,
        });
    }

    @bind
    handleDelete(commentId: number | undefined) {
        this.setState({
            deleteComment: true,
            deleteId: commentId,
        });
    }

    @bind
    async saveNewComment() {
        if (!this.state.canEdit) return;
        if (this.state.newComment) {
            const eventId = this.props.event.id ? this.props.event.id : 0;
            await this.props.videoEvents.postVideoEventComment(eventId, { comment: this.state.newComment });
            this.updateVideoEventHistory();
            this.setState({
                newComment: '',
            });
        }
    }

    @bind
    editComment(comment: VideoEventCommentResponse) {
        if (!this.state.canEdit) return;
        this.setState({
            editingId: comment.id,
            editComment: comment.comment,
        });
    }

    @bind
    cancelEditComment() {
        if (!this.state.canEdit) return;
        this.setState({
            editingId: undefined,
            editComment: '',
        });
    }

    @bind
    async saveEditComment() {
        if (!this.state.canEdit) return;
        const eventId = this.props.event.id ? this.props.event.id : 0;
        const comId = this.state.editingId;
        await this.props.videoEvents.putVideoEventComments(eventId, comId || 0, { comment: this.state.editComment });
        this.updateVideoEventHistory();
        this.setState({
            editingId: undefined,
            editComment: '',
        });
    }

    @bind
    async deleteComment() {
        if (!this.state.canEdit) return;
        const eventId = this.props.event.id ? this.props.event.id : 0;
        await this.props.videoEvents.deleteVideoEventComments(eventId, this.state.deleteId || 0);
        this.updateVideoEventHistory();
        this.cancelDelete();
    }

    @bind
    cancelDelete() {
        this.setState({
            deleteComment: false,
            deleteId: undefined,
        });
    }

    @bind
    updateVideoEventHistory() {
        if (this.props.updateVideoEventHistory) {
            this.props.updateVideoEventHistory();
        }
    }

    render() {
        const freezer = this.props.videoEvents.getState();
        const { selectedVideoEventCommentsResult } = freezer;
        const coms = selectedVideoEventCommentsResult.data ? selectedVideoEventCommentsResult.data : [];

        return (
            <div className={styles.main}>
                <Card className={styles.card}>
                    <div className={styles.cardBody}>
                        <div style={{ position: 'relative' }}>
                            <div className={!this.state.canEdit ? styles.disabledByRole : ''}></div>
                            <div className={styles.new}>
                                <div className={styles.comInput}>
                                    <TextField
                                        multiline={true}
                                        margin="dense"
                                        placeholder="Enter a new comment"
                                        fullWidth={true}
                                        variant="outlined"
                                        onChange={this.handleNewChange}
                                        value={this.state.newComment}
                                    />
                                </div>
                                <div className={styles.save}>
                                    <Button size="medium" className={styles.actionButton} onClick={() => this.saveNewComment()}>
                                        Save
                                    </Button>
                                </div>
                            </div>
                        </div>
                        <div className={styles.commentContainer}>
                            {coms.length > 0 ? (
                                coms.map((com: VideoEventCommentResponse, idx: number) => {
                                    const comDate = moment(com.createdOn);
                                    const editDate = moment(com.editedOn);
                                    return (
                                        <div key={idx} className={styles.comment}>
                                            <div className={styles.content}>
                                                <div>
                                                    <b>{com.createdBy || 'Unknown'}</b>
                                                </div>
                                                <div>
                                                    {com.comment}
                                                    {com.editedOn && com.editedOn !== com.createdOn ? (
                                                        <div className={styles.edit}>
                                                            (edited on {DateFormatter.dateWithMonthAbbr(editDate)} by{' '}
                                                            {com.editedBy || 'Unknown'})
                                                        </div>
                                                    ) : (
                                                        <div />
                                                    )}
                                                </div>
                                            </div>
                                            <div className={styles.details}>
                                                <div>
                                                    <b>{DateFormatter.getDaysAgo(comDate)}</b>
                                                </div>
                                                <div>{DateFormatter.dateWithMonthAbbr(comDate)}</div>
                                                {this.state.canEdit && (
                                                    <div className={styles.controls}>
                                                        <div className={styles.controlRight}>
                                                            <Button
                                                                onClick={() => this.handleDelete(com.id)}
                                                                className={this.state.canEdit ? styles.controlButton : styles.disabled}
                                                                size="medium"
                                                                disabled={!this.state.canEdit}
                                                            >
                                                                <FontAwesomeIcon icon={faTrash} />
                                                            </Button>
                                                        </div>
                                                        <div className={styles.controlLeft}>
                                                            <Button
                                                                onClick={() => this.editComment(com)}
                                                                className={this.state.canEdit ? styles.controlButton : styles.disabled}
                                                                size="medium"
                                                                disabled={!this.state.canEdit}
                                                            >
                                                                <FontAwesomeIcon icon={faPen} />
                                                            </Button>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    );
                                })
                            ) : (
                                <div>No comments yet</div>
                            )}
                        </div>
                    </div>
                </Card>
                <Dialog open={this.state.deleteComment}>
                    <DialogTitle>Delete Comment?</DialogTitle>
                    <DialogActions>
                        <Button onClick={this.cancelDelete} className={styles.actionButton}>
                            Cancel
                        </Button>
                        <Button onClick={this.deleteComment} className={styles.actionButton}>
                            Confirm
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog open={this.state.editingId !== undefined}>
                    <DialogTitle>Edit Comment</DialogTitle>
                    <DialogContent className={styles.dialogContent}>
                        <TextField
                            multiline={true}
                            margin="dense"
                            fullWidth={true}
                            variant="outlined"
                            onChange={this.handleEditChange}
                            value={this.state.editComment}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.cancelEditComment} className={styles.actionButton}>
                            Cancel
                        </Button>
                        <Button onClick={this.saveEditComment} className={styles.actionButton}>
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}
