import { managedAjaxUtil, React, Redirect, Route } from 'Imports';
import { CircularProgress } from 'MaterialUIComponents';

import { getSplitUserAttributes, isSplitTreatmentOn, TypedSplitTreatments, splitTreatmentNames } from '$Utilities/split-io-sdk';
import { ISplitTreatmentsChildProps } from '@splitsoftware/splitio-react/types/types';

import { IApplicationProps } from 'Application';
import { GeotabManager } from 'integrationPartner/geotabManager';

import { AuthService } from 'oauth/AuthService';
import { LoginResponse } from 'oauth/LoginResponse';
import { LogoutResponse } from 'oauth/LogoutResponse';

import { SignedAgreementHistoryResponse } from '$Generated/api';

import { DeviceAssociationSplitOff } from '$Pages/DeviceAssociation/DeviceAssociation_SplitOff';
import { DeviceAssociation } from '$Pages/DeviceAssociation/DeviceAssociation';

import { Error } from '$Pages/Error/Error';
import { GeotabConfiguration } from '$Pages/GeotabConfiguration/GeotabConfiguration';
import { GeotabLogin } from '$Pages/GeotabLogin/GeotabLogin';
import { InactiveFleet } from '$Pages/InactiveFleet/InactiveFleet';
import { Installers } from '$Pages/Installers/Installers';
import { InvalidRoute } from '$Pages/InvalidRoute/InvalidRoute';
import { MissingFleet } from '$Pages/MissingFleet/MissingFleet';
import { MultipleFleets } from '$Pages/MultipleFleets/MultipleFleets';
import { Settings } from '$Pages/Settings/Settings';
import { VideoDashboardPage } from '$Pages/VideoDashboard/VideoDashboard';
import { VideoEventDetailsPage } from '$Pages/VideoEventDetails/VideoEventDetails';
import { SplitOffVideoEventDetailsPage } from '$Pages/VideoEventDetails/_Split_Off_VideoEventDetails/Split_Off_VideoEventDetails';
import { VideoEventsPage } from '$Pages/VideoEvents/VideoEvents';
import { VideoRecallPage } from '$Pages/VideoRecall/VideoRecall';
import { VerifyRole, RolesEnum } from 'externals/VerifyRole';
import { TermsOfUseModal } from '$Components/TermsOfUse/TermsOfUseModal';

type RedirectAction = 'Wait' | 'Redirect' | 'Continue';

interface IPageRouterBaseProps {
    needToSign: (signedAgreementsResults: managedAjaxUtil.IAjaxState<SignedAgreementHistoryResponse>) => RedirectAction;
    onSelectVideoEvent: (value: string, goBack: boolean) => void;
    selectedEvent: string;
}

type IPageRouterProps = IPageRouterBaseProps & IApplicationProps;

export class PageRouter extends React.Component<IPageRouterProps> {
    render(): JSX.Element {
        return this.getValidationExceptions()
            ? this.renderExceptions()
            : this.props.config.isRunningGeotabIntegrated()
              ? this.renderGeoTabIntegratedRoutes()
              : this.renderStandardRoutes();
    }

    getValidationExceptions() {
        if (this.props.userManagement.isLoggedIn() && !this.props.config.isFleetIdValid()) {
            return true;
        }
        return false;
    }

    renderExceptions() {
        if (this.props.config.isFleetIdErrorMissing()) {
            this.props.setPageTitle('Missing Fleet');
            return <MissingFleet />;
        } else if (this.props.config.isFleetIdErrorMultiple()) {
            this.props.setPageTitle('Multiple Fleets');
            return <MultipleFleets />;
        } else if (this.props.config.isFleetIdErrorInactive()) {
            this.props.setPageTitle('Inactive Fleet');
            return <InactiveFleet />;
        } else {
            this.props.setPageTitle('Error');
            return <Error />;
        }
    }
    renderGeoTabIntegratedRoutes() {
        const { groupsFilter } = this.props.integrationPartnerData.getState();
        const { signedAgreementsResults } = this.props.agreements.getState();
        const redirectToAgreements = this.props.needToSign(signedAgreementsResults);

        return (
            <>
                <Route
                    path={'/' + GeotabManager.getGeotabUserCredentialsDatabase()}
                    exact={true}
                    render={(props: any) => {
                        const page: string = location.hash;
                        switch (true) {
                            case page.includes('addin-vidlocate-VideoRecall'):
                            case page.includes('addin-videoprotects-VideoRecall'): {
                                const selectedEventId = this.extractEventId(page, ',eventId:');
                                return redirectToAgreements === 'Redirect' ? (
                                    <TermsOfUseModal />
                                ) : (
                                    <VerifyRole allowedRole={[RolesEnum.videoRecallEdit]} restrictedMessage={true}>
                                        {selectedEventId !== ''
                                            ? this.renderVideoEventDetails(selectedEventId, true, this.props.onSelectVideoEvent)
                                            : this.renderVideoRecallPage(groupsFilter)}
                                    </VerifyRole>
                                );
                            }
                            case page.includes('addin-vidlocate-DeviceAssociations'):
                            case page.includes('addin-videoprotects-DeviceAssociations'):
                                return redirectToAgreements === 'Redirect' ? (
                                    <TermsOfUseModal />
                                ) : (
                                    <VerifyRole allowedRole={[RolesEnum.videoAdmin]} restrictedMessage={true}>
                                        <TypedSplitTreatments
                                            names={[splitTreatmentNames.deviceAssociationsUnpairedDevices]}
                                            attributes={getSplitUserAttributes()}
                                        >
                                            {({ treatments, isReady, isTimedout }: ISplitTreatmentsChildProps) => {
                                                return isReady || isTimedout ? (
                                                    isSplitTreatmentOn(
                                                        treatments[splitTreatmentNames.deviceAssociationsUnpairedDevices],
                                                    ) ? (
                                                        <DeviceAssociation groupsFilter={groupsFilter} />
                                                    ) : (
                                                        <DeviceAssociationSplitOff groupsFilter={groupsFilter} />
                                                    )
                                                ) : (
                                                    <></>
                                                );
                                            }}
                                        </TypedSplitTreatments>
                                    </VerifyRole>
                                );
                            case page.includes('addin-vidlocate-Installers'):
                            case page.includes('addin-videoprotects-Installers'):
                                return redirectToAgreements === 'Redirect' ? (
                                    <TermsOfUseModal />
                                ) : (
                                    <VerifyRole allowedRole={[RolesEnum.videoAdmin]} restrictedMessage={true}>
                                        <Installers />
                                    </VerifyRole>
                                );
                            case page.includes('addin-vidlocate-Settings'):
                            case page.includes('addin-videoprotects-Settings'):
                                return redirectToAgreements === 'Redirect' ? (
                                    <TermsOfUseModal />
                                ) : (
                                    <VerifyRole allowedRole={[RolesEnum.videoAdmin]} restrictedMessage={true}>
                                        <Settings />
                                    </VerifyRole>
                                );
                            case page.includes('addin-vidlocate-VideoDashboard'):
                            case page.includes('addin-videoprotects-VideoDashboard'):
                                return redirectToAgreements === 'Redirect' ? (
                                    <TermsOfUseModal />
                                ) : (
                                    <VerifyRole allowedRole={[RolesEnum.videoEventView, RolesEnum.videoEventEdit]} restrictedMessage={true}>
                                        <VideoDashboardPage />
                                    </VerifyRole>
                                );
                            case page.includes('addin-vidlocate-VideoEvents'):
                            case page.includes('addin-videoprotects-VideoEvents'): {
                                const selectedEventId = this.extractEventId(page, ',eventId:');
                                return redirectToAgreements === 'Redirect' ? (
                                    <TermsOfUseModal />
                                ) : (
                                    <VerifyRole allowedRole={[RolesEnum.videoEventView, RolesEnum.videoEventEdit]} restrictedMessage={true}>
                                        {selectedEventId !== '' ? (
                                            this.renderVideoEventDetails(selectedEventId, false, this.props.onSelectVideoEvent)
                                        ) : (
                                            <VideoEventsPage
                                                onSelectVideoEvent={this.props.onSelectVideoEvent}
                                                groupsFilter={groupsFilter}
                                            />
                                        )}
                                    </VerifyRole>
                                );
                            }
                            default:
                                return <InvalidRoute />;
                        }
                    }}
                />
            </>
        );
    }

    renderStandardRoutes() {
        const { groupsFilter } = this.props.integrationPartnerData.getState();
        const { signedAgreementsResults } = this.props.agreements.getState();
        const redirectToAgreements = this.props.needToSign(signedAgreementsResults);

        return (
            <>
                <Route
                    path="/error"
                    exact={true}
                    render={(props) => {
                        this.props.setPageTitle('Error');
                        return <Error />;
                    }}
                />
                <Route
                    path="/login"
                    exact={true}
                    render={(props) => {
                        this.props.setPageTitle('Login');
                        return this.props.config.isRunningEncompassStandAlone() ? (
                            <AuthService />
                        ) : this.props.config.isRunningEncompassIntegrated() ? (
                            <Redirect to="/videoevents" />
                        ) : this.props.config.isIntegrationPlatformGeotab() ? (
                            <GeotabLogin redirect={location.pathname} />
                        ) : (
                            <CircularProgress />
                        );
                    }}
                />
                <Route
                    path="/loginResponse"
                    exact={true}
                    render={(props) => {
                        this.props.setPageTitle('Login Response');
                        return this.props.userManagement.isLoggedIn() ? <LoginResponse /> : <CircularProgress />;
                    }}
                />
                <Route
                    path="/logoutResponse"
                    exact={true}
                    render={(props) => {
                        this.props.setPageTitle('Logout Response');
                        return this.props.config.getIsEncompassApiConfigReady() ? <LogoutResponse /> : <CircularProgress />;
                    }}
                />
                <Route
                    path="/"
                    exact={true}
                    render={(props: any) => {
                        const params = props.location.search;
                        const selectedEventId = this.extractEventId(params, '?eventId=');
                        this.props.setPageTitle('Video Events');
                        return redirectToAgreements == 'Redirect' ? (
                            <Redirect to="/signagreements" />
                        ) : (
                            <VerifyRole allowedRole={[RolesEnum.videoEventView, RolesEnum.videoEventEdit]} restrictedMessage={true}>
                                {selectedEventId !== '' ? (
                                    this.renderVideoEventDetails(selectedEventId, false, this.props.onSelectVideoEvent)
                                ) : (
                                    <VideoEventsPage onSelectVideoEvent={this.props.onSelectVideoEvent} groupsFilter={groupsFilter} />
                                )}
                            </VerifyRole>
                        );
                    }}
                />
                <Route
                    path="/videoevents"
                    exact={true}
                    render={(props: any) => {
                        const params = props.location.search;
                        const selectedEventId = this.extractEventId(params, '?eventId=');
                        this.props.setPageTitle('Video Events');
                        return redirectToAgreements == 'Redirect' ? (
                            <Redirect to="/signagreements" />
                        ) : (
                            <VerifyRole allowedRole={[RolesEnum.videoEventView, RolesEnum.videoEventEdit]} restrictedMessage={true}>
                                {selectedEventId !== '' ? (
                                    this.renderVideoEventDetails(selectedEventId, false, this.props.onSelectVideoEvent)
                                ) : (
                                    <VideoEventsPage onSelectVideoEvent={this.props.onSelectVideoEvent} groupsFilter={groupsFilter} />
                                )}
                            </VerifyRole>
                        );
                    }}
                />
                <Route
                    path="/videorecall"
                    exact={true}
                    render={(props: any) => {
                        const params = props.location.search;
                        const selectedEventId = this.extractEventId(params, '?eventId=');
                        this.props.setPageTitle('Video Recall');
                        return redirectToAgreements == 'Redirect' ? (
                            <Redirect to="/signagreements" />
                        ) : (
                            <VerifyRole allowedRole={[RolesEnum.videoRecallEdit]} restrictedMessage={true}>
                                {selectedEventId !== ''
                                    ? this.renderVideoEventDetails(selectedEventId, true, this.props.onSelectVideoEvent)
                                    : this.renderVideoRecallPage(groupsFilter)}
                            </VerifyRole>
                        );
                    }}
                />
                <Route
                    path="/deviceassociation"
                    exact={true}
                    render={(props: any) => {
                        this.props.setPageTitle('Device Association');
                        return redirectToAgreements == 'Redirect' ? (
                            <Redirect to="/signagreements" />
                        ) : (
                            <VerifyRole allowedRole={[RolesEnum.videoAdmin]} restrictedMessage={true}>
                                <TypedSplitTreatments
                                    names={[splitTreatmentNames.deviceAssociationsUnpairedDevices]}
                                    attributes={getSplitUserAttributes()}
                                >
                                    {({ treatments, isReady, isTimedout }: ISplitTreatmentsChildProps) => {
                                        return isReady || isTimedout ? (
                                            isSplitTreatmentOn(treatments[splitTreatmentNames.deviceAssociationsUnpairedDevices]) ? (
                                                <DeviceAssociation groupsFilter={groupsFilter} />
                                            ) : (
                                                <DeviceAssociationSplitOff groupsFilter={groupsFilter} />
                                            )
                                        ) : (
                                            <></>
                                        );
                                    }}
                                </TypedSplitTreatments>
                            </VerifyRole>
                        );
                    }}
                />
                <Route
                    path="/installers"
                    exact={true}
                    render={(props: any) => {
                        this.props.setPageTitle('Installers');
                        return redirectToAgreements == 'Redirect' ? (
                            <Redirect to="/signagreements" />
                        ) : (
                            <VerifyRole allowedRole={[RolesEnum.videoAdmin]} restrictedMessage={true}>
                                <Installers />
                            </VerifyRole>
                        );
                    }}
                />
                <Route
                    path="/settings"
                    exact={true}
                    render={(props: any) => {
                        this.props.setPageTitle('Settings');
                        return redirectToAgreements == 'Redirect' ? (
                            <Redirect to="/signagreements" />
                        ) : (
                            <VerifyRole allowedRole={[RolesEnum.videoAdmin]} restrictedMessage={true}>
                                <Settings />
                            </VerifyRole>
                        );
                    }}
                />
                <Route
                    path="/videodashboard"
                    exact={true}
                    render={(props: any) => {
                        this.props.setPageTitle('Video Dashboard');
                        return redirectToAgreements == 'Redirect' ? (
                            <Redirect to="/signagreements" />
                        ) : (
                            <VerifyRole allowedRole={[RolesEnum.videoEventView, RolesEnum.videoEventEdit]} restrictedMessage={true}>
                                <VideoDashboardPage />
                            </VerifyRole>
                        );
                    }}
                />
                <Route
                    path="/geotabaddinconfiguration"
                    exact={true}
                    render={(props: any) => {
                        this.props.setPageTitle('Geotab Addin Configuration');
                        return <GeotabConfiguration />;
                    }}
                />
                <Route
                    path="/signagreements"
                    exact={true}
                    render={(props: any) => {
                        this.props.setPageTitle('Sign Agreements');
                        return <TermsOfUseModal />;
                    }}
                />
                <Route
                    path="*"
                    exact={true}
                    render={(props: any) => {
                        return <InvalidRoute />;
                    }}
                />
            </>
        );
    }

    renderVideoEventDetails(selectedEvent: string, isVideoRecall: boolean, onSelectVideoEvent: (value: string, goBack: boolean) => void) {
        return (
            <TypedSplitTreatments names={[splitTreatmentNames.videoRecallDetailsPageRedesign]} attributes={getSplitUserAttributes()}>
                {({ treatments, isReady, isTimedout }: ISplitTreatmentsChildProps) => {
                    return isReady || isTimedout ? (
                        isSplitTreatmentOn(treatments[splitTreatmentNames.videoRecallDetailsPageRedesign]) ? (
                            <VideoEventDetailsPage
                                eventId={selectedEvent}
                                onSelectVideoEvent={onSelectVideoEvent}
                                isVideoRecall={isVideoRecall}
                            />
                        ) : (
                            <SplitOffVideoEventDetailsPage
                                eventId={selectedEvent}
                                onSelectVideoEvent={onSelectVideoEvent}
                                isVideoRecall={isVideoRecall}
                            />
                        )
                    ) : (
                        <></>
                    );
                }}
            </TypedSplitTreatments>
        );
    }

    renderVideoRecallPage(groupsFilter: any) {
        return (
            <TypedSplitTreatments names={[splitTreatmentNames.videoeventId]} attributes={getSplitUserAttributes()}>
                {({ treatments, isReady, isTimedout }: ISplitTreatmentsChildProps) => {
                    return isReady || isTimedout ? (
                        isSplitTreatmentOn(treatments[splitTreatmentNames.videoeventId]) ? (
                            <VideoRecallPage
                                onSelectVideoEvent={this.props.onSelectVideoEvent}
                                groupsFilter={groupsFilter}
                                showEventId={true}
                            />
                        ) : (
                            <VideoRecallPage
                                onSelectVideoEvent={this.props.onSelectVideoEvent}
                                groupsFilter={groupsFilter}
                                showEventId={false}
                            />
                        )
                    ) : (
                        <VideoRecallPage
                            onSelectVideoEvent={this.props.onSelectVideoEvent}
                            groupsFilter={groupsFilter}
                            showEventId={false}
                        />
                    );
                }}
            </TypedSplitTreatments>
        );
    }

    private extractEventId(urlParams: string, pattern: string): string {
        let selectedEventId = this.props.selectedEvent;
        if (!selectedEventId && urlParams.includes(pattern)) {
            const currentDecodePageUrl = decodeURI(urlParams);
            selectedEventId = currentDecodePageUrl.substring(urlParams.lastIndexOf(pattern) + pattern.length).replace(/\'/g, '');
            const currentPageURL = decodeURI(location.href);
            const hashWithOutEventId = decodeURI(location.hash).replace(/\'/g, '').replace(`${pattern}${selectedEventId}`, '');
            history.pushState(null, 'Video Event', `${location.pathname}${hashWithOutEventId}`);
            history.pushState(null, 'Video Event', `${currentPageURL.replace(/\'/g, '').replace(selectedEventId, `'${selectedEventId}'`)}`);
        }
        return selectedEventId;
    }
}
