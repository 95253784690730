import { React } from 'Imports';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH, faEllipsisV, faXmark } from '@fortawesome/pro-solid-svg-icons';
import { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { IconButton, Tooltip, styled } from 'MaterialUIComponents';

const styles = require('../styles/OptionsColumn.scss') as {
    iconButton: string;
    toolTipButton: string;
    ellipseIcon: string;
    iconButtonActive: string;
    ellipseIconActive: string;
    encIconButtonActive: string;
    encIconButton: string;
    encEllipseIcon: string;
    ellipsisAlign: string;
};

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip
        {...props}
        classes={{ popper: className }}
        slotProps={{
            popper: {
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [0, -14],
                        },
                    },
                ],
            },
        }}
    />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#fff',
        color: '#000',
        boxShadow: theme.shadows[1],
        margin: '0',
        padding: '0',
        aligntText: 'center',
        borderRadius: '4px',
    },
}));

interface IOptionsTooltipProps {
    onClick: any;
    isOpen: boolean;
    onOpenTooltip: any;
    icon: any;
    buttonText: string;
    isEncompass: boolean;
}

class OptionsTooltip extends React.Component<IOptionsTooltipProps> {
    render(): JSX.Element {
        const buttonClassName = (isOpen: boolean, isEncompass: boolean) => {
            if (isOpen) {
                if (isEncompass) return styles.encIconButtonActive;
                else return styles.iconButtonActive;
            } else {
                if (isEncompass) return styles.encIconButton;
                else return styles.iconButton;
            }
        };

        const displayIcon = (isOpen: boolean, isEncompass: boolean) => {
            if (isOpen) {
                return <FontAwesomeIcon className={styles.ellipseIconActive} icon={faXmark} style={{ color: 'white' }} />;
            } else {
                if (isEncompass) return <FontAwesomeIcon className={styles.encEllipseIcon} icon={faEllipsisH} />;
                else return <FontAwesomeIcon className={styles.ellipseIcon} icon={faEllipsisV} />;
            }
        };

        return (
            <div className={this.props.isEncompass ? styles.ellipsisAlign : undefined}>
                <StyledTooltip
                    title={
                        <div className={styles.toolTipButton} onClick={() => this.props.onClick()}>
                            {this.props.buttonText}
                        </div>
                    }
                    placement="left-start"
                    PopperProps={{
                        disablePortal: true,
                    }}
                    open={this.props.isOpen}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                >
                    <IconButton
                        className={buttonClassName(this.props.isOpen, this.props.isEncompass)}
                        onClick={() => this.props.onOpenTooltip()}
                        disableRipple={true}
                        disableFocusRipple={true}
                        size={'small'}
                    >
                        {displayIcon(this.props.isOpen, this.props.isEncompass)}
                    </IconButton>
                </StyledTooltip>
            </div>
        );
    }
}

export default OptionsTooltip;
