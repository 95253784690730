import { AutoCompleteControl } from '$Components/Shared/AutoCompleteControl';
import { DialogContent, FormControl, FormControlLabel, Switch, Typography } from 'MaterialUIComponents';
import { ISplitTreatmentsChildProps } from '@splitsoftware/splitio-react/types/types';
import { TypedSplitTreatments, getSplitUserAttributes, isSplitTreatmentOn, splitTreatmentNames } from '$Utilities/split-io-sdk';
import React = require('react');
import SliderDialog from '$Components/Shared/MaterialUIComponents/SliderDialog';

export const styles = require('pages/DeviceAssociation/styles/PairingDialog.scss') as {
    pickerContent: string;
    select: string;
    headerText: string;
    gpsTracker: string;
    switchGpsTracker: string;
    switchThumb: string;
    switchChecked: string;
    switchBase: string;
    switchRoot: string;
    switchTrackChecked: string;
    switchTrack: string;
    editPairingWarnText: string;
};

interface IPairingDialogProps {
    open: boolean;
    onClose: () => void;
    isEdit: boolean;
    handleAddPairing: any;
    vehicles: any;
    cameras: any;
    config: any;
    handleVehicleChange: any;
    device: any;
    handleCameraChange: any;
    cameraId: any;
    handleGpsTrackerChange: any;
    isGpsTracker: boolean;
}

type PairingDialogProps = IPairingDialogProps;

export function PairingDialog(props: PairingDialogProps) {
    const title = props.isEdit ? 'Edit Pairing' : 'Pair Device';
    const submitActionText = props.isEdit ? 'Save' : 'Add';
    const isForEdit = props.isEdit;
    const isEncompass = props.config?.isIntegrationPlatformEncompass();
    const isRunningStandAlone = props.config?.isRunningStandAlone();

    return (
        <SliderDialog
            open={props.open}
            title={title}
            onClose={props.onClose}
            onSubmit={props.handleAddPairing}
            submitButtonText={submitActionText}
            isEncompass={isEncompass}
            isRunningStandAlone={isRunningStandAlone}
        >
            <TypedSplitTreatments names={[splitTreatmentNames.deviceAssociationsUnpairedDevices]} attributes={getSplitUserAttributes()}>
                {({ treatments, isReady, isTimedout }: ISplitTreatmentsChildProps) => {
                    return isReady || isTimedout ? (
                        isSplitTreatmentOn(treatments[splitTreatmentNames.deviceAssociationsUnpairedDevices]) ? (
                            <>
                                {isForEdit && (
                                    <>
                                        <Typography variant="h6" component="h2" className={styles.headerText}>
                                            <strong>General Information</strong>
                                        </Typography>
                                        <Typography variant="body2" component="p" className={styles.editPairingWarnText}>
                                            Editing this pairing will result in creating a new pairing.
                                        </Typography>
                                    </>
                                )}

                                <AutoCompleteControl
                                    enabled={false}
                                    findValue={props.cameras.find((x: any) => x?.id === props.cameraId) || null}
                                    handleOnChange={props.handleCameraChange}
                                    label="Camera"
                                    optionLabelKey="serialNumber"
                                    options={props.cameras}
                                    required={true}
                                    isEncompass={isEncompass}
                                    isIntegrated={!isRunningStandAlone}
                                />
                            </>
                        ) : (
                            <>
                                <Typography variant="h6" component="h2" className={styles.headerText}>
                                    <strong>General Information</strong>
                                </Typography>

                                {isForEdit && (
                                    <Typography variant="body2" component="p" className={styles.editPairingWarnText}>
                                        Editing this pairing will result in creating a new pairing.
                                    </Typography>
                                )}

                                <AutoCompleteControl
                                    enabled={!isForEdit}
                                    findValue={props.cameras.find((x: any) => x?.id === props.cameraId) || null}
                                    handleOnChange={props.handleCameraChange}
                                    label="Camera"
                                    optionLabelKey="serialNumber"
                                    options={props.cameras}
                                    required={true}
                                    isEncompass={isEncompass}
                                    isIntegrated={!isRunningStandAlone}
                                />
                            </>
                        )
                    ) : (
                        <></>
                    );
                }}
            </TypedSplitTreatments>

            <AutoCompleteControl
                enabled={true}
                findValue={props.vehicles.find((x: any) => x.id === props.device) || null}
                handleOnChange={props.handleVehicleChange}
                label={isEncompass ? 'Vehicle' : 'Go Device'}
                optionLabelKey={isEncompass ? 'name' : 'serialNumber'}
                options={props.vehicles}
                required={true}
                isEncompass={isEncompass}
                isIntegrated={!isRunningStandAlone}
            />
            {props.config?.isIntegrationPlatformEncompass() ? (
                <TypedSplitTreatments names={[splitTreatmentNames.gpsTracker]} attributes={getSplitUserAttributes()}>
                    {({ treatments, isReady, isTimedout }: ISplitTreatmentsChildProps) => {
                        return isReady || isTimedout ? (
                            isSplitTreatmentOn(treatments[splitTreatmentNames.gpsTracker]) ? (
                                <DialogContent className={styles.pickerContent}>
                                    <FormControl className={styles.select}>
                                        <FormControlLabel
                                            value="isGpsTrackerEnabled"
                                            control={
                                                <Switch
                                                    edge="start"
                                                    checked={props.isGpsTracker}
                                                    onChange={props.handleGpsTrackerChange}
                                                    classes={{
                                                        root: styles.switchRoot,
                                                        switchBase: styles.switchBase,
                                                        checked: styles.switchChecked,
                                                        thumb: styles.switchThumb,
                                                        track: props.isGpsTracker ? styles.switchTrackChecked : styles.switchTrack,
                                                    }}
                                                />
                                            }
                                            label="GPS Tracker"
                                            labelPlacement="top"
                                            classes={{
                                                labelPlacementTop: styles.gpsTracker,
                                                root: styles.switchGpsTracker,
                                            }}
                                        />
                                    </FormControl>
                                </DialogContent>
                            ) : (
                                <></>
                            )
                        ) : (
                            <></>
                        );
                    }}
                </TypedSplitTreatments>
            ) : (
                <></>
            )}
        </SliderDialog>
    );
}
