import * as _ from 'lodash';
import * as DateFormatter from '../../../../Shared/DateFormatter';
import { DeleteButton } from '$Components/Shared/DeleteButton';
import { DeleteConfirmation } from '$Components/Shared/DeleteConfirmation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/pro-solid-svg-icons';
import { FlagButton } from '../../../../Shared/FlagButton';
import { Grid, Hidden, IconButton } from '../../../../../MaterialUIComponents';
import { IIntegrationPartnerDataInjectedProps, IntegrationPartnerDataService } from '$State/IntegrationPartnerDataFreezerService';
import { IVideoEventServiceInjectedProps, VideoEventService } from '$State/VideoEventFreezerService';
import { PlayAllButton } from '../PlayAll';
import { React, moment, unit } from '../../../../../Imports';
import { RolesEnum, canEditByRole } from '../../../../../externals/VerifyRole';
import { Section } from '../Section';
import { VideoActions } from '../VideoActions';
import { VideoEventDescription } from '../../../../Shared/VideoEventDescription';
import { VideoEventFlaggedByUsersCaption } from '../../../../Shared/VideoEventFlaggedByUsersCaption';
import { VideoEventResponse, VideoEventWorkflowStatusIdEnum, UserResponse } from '$Generated/api';
import { VideoRatingButton } from '../../../../Shared/VideoRatingButton';
import { getStatusLabel } from '../../../../../utilities/videoEventStatusUtility';
import { useEffect, useState } from 'react';

const styles = require('../VideoDetailsSection.scss') as {
    detailItem: string;
    detailItemName: string;
    detailItemValue: string;
    editDriverButton: string;
    flaggedByUsersCaptionContainer: string;
    playAllButton: string;
    playAllButtonGridItem: string;
    playIcon: string;
    sectionContent: string;
    videoCount: string;
    videoDetail: string;
};

interface IVideoEventDetailsSectionBaseProps {
    anyVideosPlaying: boolean;
    canRate: boolean;
    changeStatusAndUpdateVideoEventHistory: (status: string) => void;
    currentDriverName: string;
    event: VideoEventResponse;
    eventStatus: string | undefined;
    handleDismissVideo: (eventId: number | undefined) => void;
    handleReopened: (eventId: number | undefined) => void;
    isDismissed: boolean;
    onPlayButtonClick: () => void;
    prevNextSplitOn: boolean;
    selectedRating: number | null;
    setCanComplete: (canComplete: boolean) => void;
    setCanRate: (canRate: boolean) => void;
    setIsDismissed: (isDismissed: boolean) => void;
    setSelectedRating: (rating: number | null) => void;
    setShowEditDriver: (showEditDriver: boolean) => void;
    showEditDriver: boolean;
    updateVideoEventHistory: () => void;
    videoCount: number;
}

type IVideoEventDetailsSectionProps = IVideoEventDetailsSectionBaseProps &
    IVideoEventServiceInjectedProps &
    IIntegrationPartnerDataInjectedProps;

const getEventSpeedLimit = (event: VideoEventResponse): string => {
    const speedLimitEventId = 29;
    const criticalSpeedEventId = 34;
    const json = event.eventJson;

    if (json && (event.videoEventType?.indexOf(speedLimitEventId) !== -1 || event.videoEventType?.indexOf(criticalSpeedEventId) !== -1)) {
        let eventJson = JSON.parse(json);

        try {
            // Speed Limit is currently stored only in the EventJson which is an arbitrary JSON that comes from the TelematicProvider
            let speedLimit = 0;
            try {
                //Attempt to pull speed limit from Streamax json
                const eventSource = eventJson.filter(
                    (e: any) => e['TelematicRuleName'] == '56007' || e['TelematicRuleName'] == 'CRITICALSPEEDING',
                )[0]['Source'];

                speedLimit = JSON.parse(JSON.stringify(eventSource).toLocaleLowerCase())['alarmadditionalinfo']['speedlimit'];
            } catch {
                //Attempt to pull speed limit from MiTAC json
                speedLimit = eventJson.filter(
                    (e: any) => e['TelematicRuleName'] == '3005' || e['TelematicRuleName'] == 'CRITICALSPEEDING',
                )[0]['Source']['detectedSpeedLimit'];
            }

            let isMetric = IntegrationPartnerDataService.getUserIsMetric();
            return `${isMetric ? Math.round(unit.mphToKph(speedLimit)) : speedLimit} ${unit.getSpeedUnitString(isMetric, false)}`;
        } catch {
            return '';
        }
    }
    return '';
};

const _VideoEventDetailsSection: React.FC<IVideoEventDetailsSectionProps> = ({
    anyVideosPlaying,
    canRate,
    changeStatusAndUpdateVideoEventHistory,
    currentDriverName,
    event,
    eventStatus,
    handleDismissVideo,
    handleReopened,
    integrationPartnerData,
    isDismissed,
    onPlayButtonClick,
    prevNextSplitOn,
    selectedRating,
    setCanComplete,
    setCanRate,
    setIsDismissed,
    setSelectedRating,
    setShowEditDriver,
    showEditDriver,
    updateVideoEventHistory,
    videoCount,
    videoEvents,
}) => {
    const [canEdit, setCanEdit] = useState(false);
    const [showDeletePopup, setShowDeletePopup] = useState(false);
    const [disabledDeleteButton, setDisabledDeleteButton] = useState(false);
    const [flaggedByUsers, setFlaggedByUsers] = useState(event.flaggedByUsers ?? ([] as UserResponse[]));

    const statusLabelId = (eventStatus !== undefined ? eventStatus : '') as VideoEventWorkflowStatusIdEnum;
    const statusLabel = getStatusLabel(statusLabelId);
    const eventDate = event.eventStartDate ? moment(event.eventStartDate) : moment();
    const speedLimit = getEventSpeedLimit(event);
    const element = React.useRef<HTMLDivElement>();
    const { currentUserResult } = integrationPartnerData.getState();

    const _canComplete = (event: VideoEventResponse): boolean => {
        return event.videoEventStatus !== 'Completed' && event.videoEventStatus !== 'Dismissed';
    };

    useEffect(() => {
        setCanEdit(canEditByRole([RolesEnum.videoEventEdit]));
        setCanComplete(_canComplete(event));
    }, []);

    const onFlagVideo = async (eventId: number | undefined): Promise<void> => {
        if (!canEdit) return;
        await videoEvents.flagVideoEvent(eventId);
        await updateVideoEventHistory();
        const currentUser = currentUserResult.data;

        if (eventIsFlaggedByCurrentUser()) {
            setFlaggedByUsers(
                flaggedByUsers.filter((user: UserResponse) => {
                    return user.integrationProviderSourceId !== currentUser.id;
                }),
            );
        } else {
            setFlaggedByUsers(
                flaggedByUsers.concat([
                    {
                        id: '',
                        integrationProviderSourceId: currentUser.id,
                        email: currentUser.email,
                        displayName: `${currentUser.firstName} ${currentUser.lastName}`,
                    },
                ]),
            );
        }
    };

    const eventIsFlaggedByCurrentUser = (): boolean => {
        return (
            _.find(flaggedByUsers, (user: UserResponse) => {
                return user.integrationProviderSourceId === currentUserResult.data.id;
            }) !== undefined
        );
    };

    const handleClearRating = async (eventId: number | undefined): Promise<void> => {
        await videoEvents.clearRating(eventId);
        setCanComplete(true);
        setIsDismissed(false);
        setSelectedRating(null);
        setCanRate(true);
        changeStatusAndUpdateVideoEventHistory('In_Process');
    };

    const handleRateVideo = async (eventId: number | undefined, rating: number): Promise<void> => {
        await videoEvents.rateVideoEvent(eventId, rating);
        updateVideoEventHistory();

        setCanComplete(true);
        setSelectedRating(rating);
        setIsDismissed(false);
        setCanRate(true);
    };

    const toggleDeletePopup = () => {
        setShowDeletePopup(!showDeletePopup);
    };

    const changeToDisabled = (disabled: boolean) => {
        setDisabledDeleteButton(disabled);
    };

    const renderVideoEventDetailsButtons = () => {
        return (
            <VideoActions
                actions={[
                    <FlagButton
                        eventId={event.id}
                        flaggedByUsers={flaggedByUsers}
                        onFlagVideoEvent={onFlagVideo as (eventId: number | undefined) => void}
                    />,
                    <VideoRatingButton
                        event={event}
                        onDismissVideo={handleDismissVideo}
                        onClearRating={handleClearRating}
                        onRateVideo={handleRateVideo}
                        onReopenEvent={handleReopened}
                        selectedRating={selectedRating}
                        isDismissed={isDismissed}
                        canRate={canRate}
                        clearIsEnabled={event.videoEventStatus !== 'Completed' ? true : false}
                        anchor={element.current}
                        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                        transformOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    />,
                    <DeleteButton eventIds={event.id ?? 0} toggleDeletePopup={toggleDeletePopup} isDisabled={disabledDeleteButton} />,
                ]}
            />
        );
    };

    const toggleEditDriver = () => {
        if (!canEdit) return;
        setShowEditDriver(!showEditDriver);
    };

    return (
        <Section
            title="Event Details"
            actionButtons={renderVideoEventDetailsButtons()}
            content={
                <>
                    <div className={styles.flaggedByUsersCaptionContainer}>
                        <VideoEventFlaggedByUsersCaption tooltipPlacement="bottom" flaggedByUsers={flaggedByUsers} />
                    </div>

                    <div className={styles.sectionContent}>
                        <Grid container>
                            <Grid item sm={6} md={6} lg={12}>
                                <Grid container>
                                    <Grid item sm={12} md={12} lg={8}>
                                        <div className={styles.detailItem}>
                                            <div className={styles.detailItemName}>Event Type</div>
                                            <div className={styles.detailItemValue}>
                                                <VideoEventDescription
                                                    className={styles.videoDetail}
                                                    videoEventTypePairings={event.videoEventTypePairings}
                                                    videoEventTypeString={event.videoEventTypeString}
                                                    videoEventFontSize="14px"
                                                    isVideoEventDetails={true}
                                                />
                                            </div>
                                        </div>
                                    </Grid>
                                    {speedLimit && (
                                        <Grid item sm={12} md={12} lg={4}>
                                            <div className={styles.detailItem}>
                                                <div className={styles.detailItemName}>Posted Speed</div>
                                                <div className={styles.detailItemValue}>{speedLimit}</div>
                                            </div>
                                        </Grid>
                                    )}
                                </Grid>

                                {prevNextSplitOn && (
                                    <div className={styles.detailItem}>
                                        <div className={styles.detailItemName}>ID</div>
                                        <div className={styles.detailItemValue}>{event.displayId}</div>
                                    </div>
                                )}

                                <div className={styles.detailItem}>
                                    <div className={styles.detailItemName}>Date/Time</div>
                                    <div className={styles.detailItemValue}>
                                        {DateFormatter.date(eventDate)}
                                        <br />
                                        {DateFormatter.timeWithSecondsAndTimezone(eventDate)}
                                    </div>
                                </div>
                                <div className={styles.detailItem}>
                                    <div className={styles.detailItemName}>Event Status</div>
                                    <div className={styles.detailItemValue}>{statusLabel}</div>
                                </div>
                            </Grid>

                            <Grid item sm={6} md={6} lg={12}>
                                <div className={styles.detailItem}>
                                    <div className={styles.detailItemName}>Driver</div>
                                    <div className={styles.detailItemValue}>
                                        <span>{currentDriverName.trim() !== '' ? currentDriverName : 'N/A'}</span>

                                        <IconButton
                                            className={styles.editDriverButton}
                                            aria-label="Edit driver name"
                                            title="Edit driver name"
                                            onClick={toggleEditDriver}
                                            size="small"
                                            disabled={!canEdit}
                                        >
                                            <FontAwesomeIcon icon={faEdit} size="xs" />
                                        </IconButton>
                                    </div>
                                </div>
                                <div className={styles.detailItem}>
                                    <div className={styles.detailItemName}>Vehicle</div>
                                    <div className={styles.detailItemValue}>{event.vehicleDescription || 'NA'}</div>
                                </div>
                                <div className={styles.detailItem}>
                                    <div className={styles.detailItemName}>Camera Serial Number</div>
                                    <div className={styles.detailItemValue}>{event.cameraDeviceSerialNumber || 'NA'}</div>
                                </div>
                            </Grid>

                            {videoCount > 1 && (
                                <Hidden lgUp smDown>
                                    <Grid item className={styles.playAllButtonGridItem} md={12}>
                                        <PlayAllButton onPlayButtonClick={onPlayButtonClick} anyVideosPlaying={anyVideosPlaying} />
                                    </Grid>
                                </Hidden>
                            )}
                        </Grid>
                    </div>
                    <DeleteConfirmation
                        eventIds={[event.id ?? 0]}
                        showDeletePopup={showDeletePopup}
                        toggleDeletePopup={toggleDeletePopup}
                        changeToDisabled={changeToDisabled}
                    />
                </>
            }
        />
    );
};

export const VideoEventDetailsSection = IntegrationPartnerDataService.inject(VideoEventService.inject(_VideoEventDetailsSection));
