import { React, bind } from 'Imports';
import { Checkbox, TextField, FormGroup, FormControlLabel } from 'MaterialUIComponents';
import { IDropdownItem } from '$Components/FilterComponents/VPDropdown';
import * as scssStyles from '$CSS/settings.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/pro-solid-svg-icons';
interface IMultiselectProps {
    onSelectedItemChange: (value: any[]) => void;
    selectedValues: any[] | undefined;
    items: IDropdownItem[];
    name: string;
    id?: string;
}

interface IMultiselectState {
    allSelected: boolean;
}

const styles = require('./VPMultiselect.scss') as {
    select: string;
    checkbox: string;
    dropdownItemIcon: string;
    dropdownIcon: string;
    menuItem: string;
};

const inputStyles = {
    borderRadius: '4px',
    backgroundColor: '#FFFFF',
    border: '1px solid #ced4da',
    boxShadow: 'inset 0px 3px 3px 0px #e6e6e6',
    padding: '0px 8px',
    fontFamily: scssStyles.fontFamily,
};
export class VPMultiselect extends React.Component<IMultiselectProps, IMultiselectState> {
    state = {
        allSelected: this.props.selectedValues && this.props.selectedValues.length > 0 ? this.props.selectedValues.length === this.props.items.length : false,
    };

    @bind
    updateSelectedValues(selectedValues: any[]): void {
        this.props.onSelectedItemChange(selectedValues);
    }

    @bind
    handleChange(event: any): void {
        const values = event;
        if (event.target.value === 'toggle') {
            this.setState(
                (state) => ({
                    allSelected: !state.allSelected,
                }),
                () => {
                    this.updateSelectedValues(this.toggleAll(this.state.allSelected));
                },
            );
        } else {
            let selectedValues = this.props.selectedValues?.map((item) => item) ?? [];
            const value = Number.isNaN(parseInt(event.target.value)) ? event.target.value : parseInt(event.target.value);

            if (event.target.checked) {
                selectedValues.includes(value) ? null : selectedValues.push(value);
            } else {
                selectedValues = selectedValues.filter((item) => item != value);
            }

            this.setState({
                allSelected: selectedValues.length == this.props.items.length,
            });

            this.updateSelectedValues(selectedValues);
        }
    }

    @bind
    renderValue(): string {
        const selectedValues = this.props.selectedValues ?? [];
        // Use selectedValues to determine display names, in order to allow other fields than Event Status to be multiselects
        const displayArray = this.props.items
            .map((item) => {
                if (selectedValues.indexOf(item.value) > -1) return item.label;
                else return null;
            })
            .filter((x) => x != null);
        return displayArray.join(', ');
    }

    @bind
    toggleAll(allSelected: boolean): number[] | [] {
        const selectedValues = allSelected ? this.props.items.map((item) => item.value) : [];
        return selectedValues;
    }

    render(): JSX.Element {
        return (
            <>
                <TextField
                    select
                    SelectProps={{
                        multiple: true,
                        renderValue: this.renderValue,
                        IconComponent: (props) => (
                            <FontAwesomeIcon icon={faAngleDown} {...props} className={[props.className, styles.dropdownIcon].join(' ')} />
                        ),
                        MenuProps: {
                            autoFocus: false,
                            anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'left',
                            },
                            transformOrigin: {
                                vertical: 'top',
                                horizontal: 'left',
                            },
                        },
                    }}
                    className={styles.select}
                    label={this.props.name}
                    id={this.props.id}
                    InputProps={{
                        style: scssStyles.styleEnvironment == 'encompass' ? inputStyles : { fontFamily: scssStyles.fontFamily },
                        disableUnderline: scssStyles.styleEnvironment == 'encompass',
                    }}
                    value={this.props.selectedValues}
                >
                    <FormGroup>
                        <FormControlLabel
                            id={`${this.props.id}-all`}
                            key={'toggle'}
                            control={
                                <Checkbox
                                    style={{ marginRight: 8, padding: 0 }}
                                    className={styles.checkbox}
                                    checked={this.state.allSelected}
                                    value={'toggle'}
                                    onChange={(event: any): void => {
                                        this.handleChange(event);
                                    }}
                                />
                            }
                            label={'Select/Deselect All'}
                            sx={{ display: 'flex', padding: '8px 16px' }}
                        />
                        {this.props.items.map((item) => (
                            <FormControlLabel
                                key={item.label + item.value}
                                control={
                                    <Checkbox
                                        style={{ marginRight: 8, padding: 0 }}
                                        className={styles.checkbox}
                                        checked={this.props.selectedValues ? this.props.selectedValues.indexOf(item.value) > -1 : undefined}
                                        value={item.value}
                                        onChange={(event: any): void => {
                                            this.handleChange(event);
                                        }}
                                    />
                                }
                                label={item.label}
                                sx={{ display: 'flex', padding: '8px 16px' }}
                            />
                        ))}
                    </FormGroup>
                </TextField>
            </>
        );
    }
}
