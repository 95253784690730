import {
    TableBody,
    TableCell,
    TableHead,
    Table,
    TableRow,
    TableSortLabel,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Card,
    FormControl,
    TextField,
    Autocomplete,
    InputAdornment,
    CircularProgress,
    ValidatorForm,
    ClickAwayListener,
    Tooltip,
    styled,
} from 'MaterialUIComponents';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faAngleDown, faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { React, bind, moment, _ } from 'Imports';
import { filterPageData } from '$Components/Shared/Paginator';
import { VehicleCameraPairingResponse, VehicleCameraPairingRequest, VehicleRequest, EditVehicleCameraPairingRequest } from '$Generated/api';
import * as DateFormatter from '$Components/Shared/DateFormatter';
import { IConfigServiceInjectedProps, ConfigService } from '$State/ConfigFreezerService';
import { IDeviceAssociationServiceInjectedProps, DeviceAssociationService } from '$State/DeviceAssociationFreezerService';
import { IIntegrationPartnerDataInjectedProps, IntegrationPartnerDataService } from '$State/IntegrationPartnerDataFreezerService';
import { PageHeader } from '$Components/Shared/PageHeader';
import { VPDropdown, IDropdownItem } from '$Components/FilterComponents/VPDropdown';
import { SortDropdownItems } from '$Utilities/dataModelUtilities';
import {
    getSplitUserAttributes,
    isSplitTreatmentOn,
    TypedSplitTreatments,
    splitTreatmentNames,
    isSplitTreatmentsOnWithDefault,
} from '$Utilities/split-io-sdk';
import { ISplitTreatmentsChildProps } from '@splitsoftware/splitio-react/types/types';
import { OptionsColumn } from './components/OptionsColumn';
import { LiveVideo } from './components/LiveVideo';
import { DeviceAssociationExport } from './DeviceAssociationExport';
import { PairingDialog } from './components/PairingDialog';
import { VideoRequestDialog } from './components/VideoRequestDialog';
import { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

import * as scssStyles from '$CSS/settings.scss';
import { AlertMessage } from '$Components/Shared/AlertMessage';
import { AlertTypes } from '$Utilities/Enums/AlertTypes';
import { ConfirmLiveVideo } from './components/ConfirmLiveVideo';
import { TermsOfUse } from '$Components/Shared/TermsOfUse';
import { PageHeader_Split_Off } from '$Components/Shared/PageHeader_Split_Off';

const InfiniteScroll: any = require('react-infinite-scroller');

interface IDeviceAssociationBaseProps {
    groupsFilter?: string;
}

type IDeviceAssociationProps = IDeviceAssociationBaseProps &
    IDeviceAssociationServiceInjectedProps &
    IIntegrationPartnerDataInjectedProps &
    IConfigServiceInjectedProps;

interface IDeviceAssociationState {
    isTooltipOpen: boolean;
    serialFilter: string;
    selectedPairingStatusFilter: string[];
    selectedVehiclesFilter: string[];
    sort: string;
    sortOrder: 'asc' | 'desc';
    currentPageNumber: number;
    pageItems: VehicleCameraPairingResponse[];
    allItems: VehicleCameraPairingResponse[];
    filteredItems: VehicleCameraPairingResponse[];
    showPairing: boolean;
    showRequestVideo: boolean;
    device: any;
    cameraId: number | undefined;
    showDelete: boolean;
    deletePairingId: number | undefined;
    disableAddRemoveButton: boolean;
    devicesLoaded: boolean;
    groupsFilter: string;
    openTooltips: boolean[];
    showLiveVideo: boolean;
    showConfirmLiveVideo: boolean;
    alertDriver: boolean;
    vehicleCameraPairingSelected: number | undefined;
    csvTitle: string;
    editParingId: number | undefined;
    gpsTrackerEnabled: boolean;
    openSnackBar: boolean;
    alertType: AlertTypes;
    alertMessage: string;
    deviceId: string | undefined;
    currentVehicleIdForEditing: string | undefined;
}

const styles = require('pages/DeviceAssociation/DeviceAssociation.scss') as {
    main: string;
    filterBar: string;
    serialFilter: string;
    vehicleFilter: string;
    buttonContainer: string;
    addButton: string;
    card: string;
    sortLabel: string;
    endPairing: string;
    pickerContent: string;
    pickerLabel: string;
    select: string;
    scrollWindow: string;
    devicesTable: string;
    headerRow: string;
    bodyRow: string;
    endPairingEncompass: string;
    filterBarEncompass: string;
    headerCell: string;
    bodyCell: string;
    noAssociations: string;
    closeIcon: string;
    closeButtonSerialFilter: string;
    inputAdormentSerialFilter: string;
    dropdownIcon: string;
    clearIcon: string;
    dialog: string;
    optionsColumn: string;
    encompassOptionColumn: string;
    liveVideoDialog: string;
    onlineStreamaxStatus: string;
    infoButton: string;
    noSortingLabel: string;
};

const inputStyles = {
    borderRadius: '4px',
    backgroundColor: '#FFFFF',
    border: '1px solid #ced4da',
    boxShadow: 'inset 0px 3px 3px 0px #e6e6e6',
    padding: '0px 8px',
};

const pageItemCount = 20;

class _DeviceAssociation extends React.Component<IDeviceAssociationProps, IDeviceAssociationState> {
    state: IDeviceAssociationState = {
        isTooltipOpen: false,
        serialFilter: '',
        selectedPairingStatusFilter: [],
        selectedVehiclesFilter: [],
        sort: 'startDate',
        sortOrder: 'asc',
        currentPageNumber: 1,
        pageItems: [],
        filteredItems: [],
        allItems: [],
        showPairing: false,
        device: { Id: '0' },
        cameraId: undefined,
        showDelete: false,
        deletePairingId: undefined,
        disableAddRemoveButton: false,
        devicesLoaded: false,
        openTooltips: [],
        showLiveVideo: false,
        showConfirmLiveVideo: false,
        alertDriver: false,
        vehicleCameraPairingSelected: undefined,
        groupsFilter: '',
        csvTitle: 'DeviceAssociations',
        editParingId: undefined,
        gpsTrackerEnabled: false,
        showRequestVideo: false,
        openSnackBar: false,
        alertType: AlertTypes.Alert,
        alertMessage: '',
        deviceId: undefined,
        currentVehicleIdForEditing: undefined,
    };

    private scrollParentRef2: any;

    async componentDidMount(): Promise<any> {
        await this.props.integrationPartnerData.getDevices();
        await this.handleDeviceMapping();
        await this.props.deviceAssociation.getAvailableDevices();
        await this.props.deviceAssociation.getUnpairedCameraDevices();

        this.setPagedItems();
        this.state.devicesLoaded = true;
    }

    setPagedItems(): void {
        const freezer = this.props.deviceAssociation.getState();
        const { availableDevicesResults } = freezer;
        const items = availableDevicesResults.data ? availableDevicesResults.data : [];
        this.setState({
            pageItems: filterPageData(1, pageItemCount, items),
            filteredItems: items,
            allItems: items,
            currentPageNumber: 1,
        });
    }

    @bind
    getPage(page: number): void {
        const nextPage = this.state.currentPageNumber + 1;
        this.setState({
            pageItems: this.state.pageItems.concat(filterPageData(nextPage, pageItemCount, this.state.filteredItems)),
            currentPageNumber: nextPage,
        });
    }

    compareArrays(a: any[], b: any[]): boolean {
        return a.length === b.length && a.every((element, index) => element === b[index]);
    }

    @bind
    handleVehicleFilterChange(vehicleIds: string[]): void {
        this.setState({
            selectedVehiclesFilter: vehicleIds,
        });
        // apply existing sort after filtering data
        this.handleRequestSort(undefined, this.applyFilters(vehicleIds, undefined, undefined));
    }

    @bind
    handleSerialFilterChange(event: any): void {
        const newSerial: string = event?.target.value ?? '';

        this.setState({
            serialFilter: newSerial,
        });

        // apply existing sort after filtering data
        this.handleRequestSort(undefined, this.applyFilters(undefined, newSerial.toUpperCase().trim(), undefined));
    }

    @bind
    handlePairingStatusFilterChange(vehicleStatus: string[]): void {
        this.setState({
            selectedPairingStatusFilter: vehicleStatus,
        });

        this.handleRequestSort(undefined, this.applyFilters(undefined, undefined, vehicleStatus));
    }

    @bind
    handleClearSerialFilterClick(): void {
        this.setState({
            serialFilter: '',
        });

        // apply existing sort after filtering data
        this.handleRequestSort(undefined, this.applyFilters(undefined, ''));
    }

    handleRequestSort(field?: string, filteredItems?: VehicleCameraPairingResponse[]): void {
        const { sort, sortOrder } = this.state;
        let isAsc: boolean = sortOrder === 'asc';

        if (!field) {
            // if no field specified, keep existing sort (i.e. a filter was changed)
            field = sort;
        } else if (field === sort) {
            // reverse current sort if same field selected
            isAsc = !isAsc;
        } else {
            // always sort new field ascending
            isAsc = true;
        }

        // if no items specified, use existing filtered items set (or fall back to all items)
        if (!filteredItems) {
            filteredItems = this.state.filteredItems || this.state.allItems;
        }

        const sortItems = filteredItems.sort((a, b) => {
            let res = 0;
            let aString = '';
            let bString = '';
            switch (field) {
                case 'cameraSerial':
                    aString = a.cameraDevice && a.cameraDevice.serialNumber ? a.cameraDevice.serialNumber : '';
                    bString = b.cameraDevice && b.cameraDevice.serialNumber ? b.cameraDevice.serialNumber : '';
                    res = aString.localeCompare(bString);
                    break;
                case 'cameraType':
                    aString =
                        a.cameraDevice && a.cameraDevice.deviceType && a.cameraDevice.deviceType.sysName
                            ? a.cameraDevice.deviceType.sysName
                            : '';
                    bString =
                        b.cameraDevice && b.cameraDevice.deviceType && b.cameraDevice.deviceType.sysName
                            ? b.cameraDevice.deviceType.sysName
                            : '';
                    res = aString.localeCompare(bString);
                    break;
                case 'goSerial':
                    aString =
                        a.vehicle?.telematicDevice && a.vehicle?.telematicDevice.serialNumber
                            ? a.vehicle?.telematicDevice.serialNumber
                            : '';
                    bString =
                        b.vehicle?.telematicDevice && b.vehicle?.telematicDevice.serialNumber
                            ? b.vehicle?.telematicDevice.serialNumber
                            : '';
                    res = aString.localeCompare(bString);
                    break;
                case 'vehicle':
                    aString = a.vehicle && a.vehicle.name ? a.vehicle.name : '';
                    bString = b.vehicle && b.vehicle.name ? b.vehicle.name : '';
                    res = aString.localeCompare(bString);
                    break;
                case 'startDate':
                    const aStart = a.startDate ? moment(a.startDate) : moment();
                    const bStart = b.startDate ? moment(b.startDate) : moment();
                    res = aStart.valueOf() - bStart.valueOf() > 0 ? 1 : aStart.valueOf() - bStart.valueOf() < 0 ? -1 : 0;
                    break;
                case 'lastHeartbeat':
                    const alastHearbeatStart =
                        a.cameraDevice && a.cameraDevice.lastHeartbeatTime ? moment(a.cameraDevice.lastHeartbeatTime) : moment();
                    const blastHearbeatStart =
                        b.cameraDevice && b.cameraDevice.lastHeartbeatTime ? moment(b.cameraDevice.lastHeartbeatTime) : moment();
                    res =
                        alastHearbeatStart.valueOf() - blastHearbeatStart.valueOf() > 0
                            ? 1
                            : alastHearbeatStart.valueOf() - blastHearbeatStart.valueOf() < 0
                              ? -1
                              : 0;
                    break;
                case 'lastProcessedEventTime':
                    const alastEventStart =
                        a.cameraDevice && a.cameraDevice.lastProcessedEventTime ? moment(a.cameraDevice.lastProcessedEventTime) : moment();
                    const blastEventStart =
                        b.cameraDevice && b.cameraDevice.lastProcessedEventTime ? moment(b.cameraDevice.lastProcessedEventTime) : moment();
                    res =
                        alastEventStart.valueOf() - blastEventStart.valueOf() > 0
                            ? 1
                            : alastEventStart.valueOf() - blastEventStart.valueOf() < 0
                              ? -1
                              : 0;
                    break;
                case 'isGpsTrackingEnabled':
                    res = a.isGpsTrackingEnabled === b.isGpsTrackingEnabled ? 0 : a.isGpsTrackingEnabled ? -1 : 1;
                    break;
                case 'pairingStatus':
                    aString = a.cameraDevice?.pairingStatus ?? '';
                    bString = b.cameraDevice?.pairingStatus ?? '';
                    res = aString.localeCompare(bString);
                    break;
                default:
                // unhandle //
            }
            return isAsc ? res : -1 * res;
        });
        this.setState({
            sort: field,
            sortOrder: isAsc ? 'asc' : 'desc',
            pageItems: filterPageData(1, pageItemCount, sortItems),
            filteredItems: sortItems,
            currentPageNumber: 1,
        });
    }

    @bind
    async handleVehicleChange(event: any): Promise<void> {
        // get vehicle
        if (event.target.textContent !== '') {
            const { deviceResults } = this.props.integrationPartnerData.getState();
            const devices = deviceResults.data ? deviceResults.data : [];
            let selectedDevice = undefined;
            if (this.props.config.isIntegrationPlatformGeotab()) {
                selectedDevice = devices.find((d) => d.serialNumber === event.target.textContent);
            } else {
                selectedDevice = devices.find((d) => d.name === event.target.textContent);
            }

            this.setState({
                device: selectedDevice,
                deviceId: selectedDevice.id,
            });
        } else {
            this.setState({
                device: undefined,
                deviceId: undefined,
            });
        }
    }

    @bind
    async handleCameraChange(event: any): Promise<void> {
        // get camera Id
        const freezer = this.props.deviceAssociation.getState();
        const { cameraResults } = freezer;
        const cameras = cameraResults.data ? cameraResults.data : [];
        const camera = cameras.find((c) => c.serialNumber === event.target.textContent);
        const cameraId = camera ? (camera.id ? camera.id : 0) : 0;

        this.setState({
            cameraId: cameraId,
        });
    }

    @bind
    toggleOpenPairing(id?: number, cameraId?: number): void {
        const { availableDevicesResults } = this.props.deviceAssociation.getState();
        const deviceAssociation = availableDevicesResults.data?.find((a) => a.id === id);

        const unpairedCameraId = availableDevicesResults.data?.find((a) => a.cameraDevice?.id === cameraId)?.cameraDevice?.id;
        if (cameraId) {
            this.setState({
                showPairing: !this.state.showPairing,
                cameraId: unpairedCameraId,
            });
        } else {
            this.setState({
                showPairing: !this.state.showPairing,
                editParingId: id,
                cameraId: deviceAssociation?.cameraDeviceId,
                deviceId: deviceAssociation?.vehicle?.integrationPartnerVehicleId,
                currentVehicleIdForEditing: deviceAssociation?.vehicle?.integrationPartnerVehicleId,
            });

            if (this.props.config.isIntegrationPlatformEncompass()) {
                this.setState({
                    gpsTrackerEnabled:
                        deviceAssociation?.isGpsTrackingEnabled != undefined ? deviceAssociation?.isGpsTrackingEnabled : true,
                });
            }
        }

        this.closeTooltips();
    }

    @bind
    toggleOpenRequestVideo(id?: number): void {
        const { availableDevicesResults } = this.props.deviceAssociation.getState();
        const deviceAssociation = availableDevicesResults.data?.find((a) => a.id === id);

        this.setState({
            showRequestVideo: !this.state.showRequestVideo,
            editParingId: id,
            cameraId: deviceAssociation?.cameraDeviceId,
            device: deviceAssociation?.vehicle?.integrationPartnerVehicleId,
        });

        this.closeTooltips();
    }

    @bind
    async handleAddPairing(): Promise<void> {
        this.setState({
            disableAddRemoveButton: true,
        });

        if (!this.props.deviceAssociation.freezer.get().availableDevicesResults.isFetching) {
            const device = this.state.device;
            const cameraId = this.state.cameraId;
            const gpsTrackerEnabled = this.state.gpsTrackerEnabled;
            const integrationPartnerVehicleId = this.state.deviceId;
            const editPairingId = this.state.editParingId;

            if (editPairingId) {
                try {
                    const vehicle: VehicleRequest = {
                        integrationPartnerVehicleId: integrationPartnerVehicleId,
                        name: device.name,
                    };
                    if (this.props.config.isIntegrationPlatformGeotab()) {
                        vehicle.telematicDevice = {
                            deviceId: device.id,
                            name: device.name,
                            type: device.deviceType,
                            serialNumber: device.serialNumber,
                        };
                    }
                    const editRequest: EditVehicleCameraPairingRequest = {
                        cameraDeviceId: cameraId,
                        vehicle: vehicle,
                        isGpsTrackingEnabled: gpsTrackerEnabled,
                        currentPairingId: editPairingId,
                    };
                    await this.props.deviceAssociation.putVehicleCameraPairing(editRequest);
                    this.setState({ openSnackBar: true, alertType: AlertTypes.Success, alertMessage: 'Changes saved successfully.' });
                } catch (error) {
                    console.log(error);
                    this.setState({ openSnackBar: true, alertType: AlertTypes.Fail, alertMessage: 'Unable to save changes.' });
                } finally {
                    await this.handleAfterAddingEvent();
                }
            } else {
                const vehicle: VehicleRequest = {
                    integrationPartnerVehicleId: device.id,
                    name: device.name,
                };
                if (this.props.config.isIntegrationPlatformGeotab()) {
                    vehicle.telematicDevice = {
                        deviceId: device.id,
                        name: device.name,
                        type: device.deviceType,
                        serialNumber: device.serialNumber,
                    };
                }
                const newPairing: VehicleCameraPairingRequest = {
                    vehicle: vehicle,
                    cameraDeviceId: cameraId,
                    isGpsTrackingEnabled: gpsTrackerEnabled,
                };

                if (cameraId !== undefined) {
                    await this.props.deviceAssociation.postVehicleCameraPairing(newPairing);
                    await this.handleAfterAddingEvent();
                } else {
                    console.error('Unable to add new pairing: Camera can not be undefined.');
                }
            }
        }
        this.setState({
            disableAddRemoveButton: false,
        });
    }

    async handleDeviceMapping(): Promise<void> {
        this.setState({
            disableAddRemoveButton: true,
        });

        // TODO This process should be move to a background process (Gerald's suggestion) TBD
        if (!this.props.deviceAssociation.freezer.get().availableDevicesResults.isFetching) {
            const { deviceResults } = this.props.integrationPartnerData.getState();
            const devices = deviceResults.data ? deviceResults.data : [];

            const vehiclesData: VehicleRequest[] = devices.map((d) => ({ integrationPartnerVehicleId: d.id, name: d.name }));

            if (vehiclesData !== undefined) {
                await this.props.deviceAssociation.mapDeviceAssociations(vehiclesData);
            } else {
                console.error('Unable to map device names, couldnt find integrationpartner data.');
            }
        }
        this.setState({
            disableAddRemoveButton: false,
        });
    }

    toggleDeletePairing(id: number | undefined): void {
        this.setState({
            showDelete: !this.state.showDelete,
            deletePairingId: id,
        });

        this.closeTooltips();
    }

    @bind
    async handleDeletePairing(): Promise<void> {
        if (!this.props.deviceAssociation.freezer.get().availableDevicesResults.isFetching) {
            await this.props.deviceAssociation.endVehicleCameraPairing(this.state.deletePairingId || 0);
            this.setState({
                showDelete: false,
                deletePairingId: undefined,
            });
            await this.props.deviceAssociation.getAvailableDevices();
            await this.props.deviceAssociation.getUnpairedCameraDevices();
            this.setPagedItems();
        }
    }

    @bind
    handleOpenTooltip(idx: number): void {
        let tooltips = _.clone(this.state.openTooltips);

        if (tooltips[idx]) {
            tooltips[idx] = false;
        } else {
            tooltips = tooltips.filter((t) => t).map((t) => (t = false));
            tooltips[idx] = tooltips[idx] ? false : true;
        }

        this.setState({
            openTooltips: tooltips,
        });
    }

    @bind
    handleConfirmLiveVideo(openLiveVideo: boolean, vehicleCameraPairingId: number | undefined) {
        this.setState({
            showConfirmLiveVideo: openLiveVideo,
            vehicleCameraPairingSelected: vehicleCameraPairingId,
        });

        this.closeTooltips();
    }

    @bind
    handleShowLiveVideo(showLiveVideo: boolean, alertDriver: boolean) {
        this.setState({
            showLiveVideo,
            alertDriver,
            showConfirmLiveVideo: false,
        });
    }

    closeTooltips(): void {
        let tooltips = _.clone(this.state.openTooltips);
        tooltips = tooltips.map((t) => (t = false));
        this.setState({
            openTooltips: tooltips,
        });
    }

    @bind
    handleGpsTrackerChange(event: any): void {
        this.setState({
            gpsTrackerEnabled: event.target.checked,
        });
    }

    //#region Internal

    private applyFilters(vehicleIds?: string[], serial?: string, pairingStatus?: string[]): VehicleCameraPairingResponse[] {
        const { allItems } = this.state;
        const filterVehicleIds = vehicleIds || this.state.selectedVehiclesFilter;
        const filterSerial = serial !== undefined ? serial : this.state.serialFilter;
        const filterPairing = pairingStatus || this.state.selectedPairingStatusFilter;
        let filteredItems = allItems;

        if (filterVehicleIds.length) {
            filteredItems = filteredItems.filter(
                (x) => x.vehicle?.integrationPartnerVehicleId && filterVehicleIds.indexOf(x.vehicle.integrationPartnerVehicleId) > -1,
            );
        }

        if (filterSerial) {
            filteredItems = filteredItems.filter(
                (x) =>
                    (x.cameraDevice?.serialNumber && x.cameraDevice.serialNumber.toUpperCase().indexOf(filterSerial) > -1) ||
                    (this.props.config.isIntegrationPlatformGeotab() &&
                        x.vehicle?.telematicDevice?.serialNumber &&
                        x.vehicle?.telematicDevice?.serialNumber.toUpperCase().indexOf(filterSerial) > -1),
            );
        }

        if (filterPairing.length > 0) {
            filteredItems = filteredItems.filter(
                (x) => x.cameraDevice?.pairingStatus && filterPairing.indexOf(x.cameraDevice?.pairingStatus) > -1,
            );
        }

        return filteredItems;
    }

    mapDeviceList = (devices: any[]): IDropdownItem[] => {
        const devicesList: IDropdownItem[] = devices
            .filter(({ vehicle }) => vehicle && vehicle.name && vehicle.integrationPartnerVehicleId)
            .map(({ vehicle }) => ({
                label: vehicle.name,
                value: vehicle.integrationPartnerVehicleId,
            }));

        return SortDropdownItems(devicesList);
    };

    mapPairingStatuses = (): IDropdownItem[] => {
        const pairingStatus: IDropdownItem[] = [
            {
                label: 'Paired',
                value: 'Paired',
            },
            {
                label: 'Unpaired',
                value: 'Unpaired',
            },
        ];

        return SortDropdownItems(pairingStatus);
    };

    async handleFilterGroups(deviceAssociation: any[]) {
        const existing = this.state.allItems?.map((item) => item.id);
        const newdevices = deviceAssociation.map((item) => item.id);
        if (!this.compareArrays(existing, newdevices)) {
            this.setPagedItems();
        }
    }

    async componentDidUpdate(prevProps: Readonly<IDeviceAssociationProps>): Promise<void> {
        if (this.props.groupsFilter !== prevProps.groupsFilter) {
            await IntegrationPartnerDataService.getDevices();
            await DeviceAssociationService.getAvailableDevices();

            // Only include selectedValues that exists in the vehicle list
            const { deviceResults } = this.props.integrationPartnerData.getState();
            const vehicles = deviceResults.data ? deviceResults.data : [];

            this.setState({
                selectedVehiclesFilter: this.state.selectedVehiclesFilter.filter((v) => vehicles.findIndex((l) => l.id === v) > -1),
            });
        }
    }

    private async handleAfterAddingEvent() {
        this.setState({
            showPairing: false,
            device: {},
            cameraId: undefined,
            gpsTrackerEnabled: false,
            deviceId: undefined,
        });

        await this.props.deviceAssociation.getAvailableDevices();
        await this.props.deviceAssociation.getUnpairedCameraDevices();
        this.setPagedItems();
    }
    handleTooltipClose = () => {
        this.setState({ isTooltipOpen: false });
    };

    handleTooltipOpen = () => {
        this.setState({ isTooltipOpen: true });
    };
    //#endregion

    render(): JSX.Element {
        const { cameraResults, availableDevicesResults } = this.props.deviceAssociation.getState();
        const { pageItems, selectedVehiclesFilter, serialFilter, allItems, selectedPairingStatusFilter } = this.state;

        const cameras = cameraResults.data ? cameraResults.data : [];
        const deviceAssociations = availableDevicesResults.data ? availableDevicesResults.data : [];

        const { deviceResults } = this.props.integrationPartnerData.getState();

        //Note: we may want to filter out inactive/disposed devices here to hide them altogether on this page, once there's a process in place to unpair disposed/inactive devices automatically.
        //For now, we'll want them to show up in case the vehicle is disposed in Encompass or inactive in Geotab, so we can manually unpair if needed
        const devices = deviceResults.data ? deviceResults.data : [];
        const vehicles = this.mapDeviceList(allItems);
        const pairingStatuses = this.mapPairingStatuses();

        const unpairedVehicles = devices
            .filter((d) => deviceAssociations.findIndex((pairing) => pairing.vehicle?.integrationPartnerVehicleId === d.id) < 0)
            .filter((d) => d.isActive);

        const editableVehiclesForPairing = devices
            .filter(
                (d) =>
                    deviceAssociations.findIndex((pairing) => pairing.vehicle?.integrationPartnerVehicleId === d.id) < 0 ||
                    d.id === this.state.currentVehicleIdForEditing,
            )
            .filter((d) => d.isActive);

        const scssEnvironment = scssStyles.styleEnvironment;
        const rowHeaders = [
            {
                id: 'cameraSerial',
                label: 'Camera Serial #',
            },
            {
                id: 'cameraType',
                label: 'Camera Device Type',
            },
            {
                id: 'vehicle',
                label: 'Paired Vehicle',
            },
            {
                id: 'startDate',
                label: 'Date Paired',
            },
            {
                id: 'pairingStatus',
                label: 'Pairing Status',
            },
        ];

        const heartbeatRowHeaders = [
            {
                id: 'cameraSerial',
                label: 'Camera Serial #',
            },
            {
                id: 'cameraType',
                label: 'Camera Device Type',
            },
            {
                id: 'vehicle',
                label: 'Paired Vehicle',
            },
            {
                id: 'startDate',
                label: 'Date Paired',
            },
            {
                id: 'lastHeartbeat',
                label: 'Last Heartbeat',
            },
            {
                id: 'lastProcessedEventTime',
                label: 'Last Processed Event',
            },
            {
                id: 'pairingStatus',
                label: 'Pairing Status',
            },
            {
                id: 'connectionStatus',
                label: 'Connection Status',
                icon: <FontAwesomeIcon icon={faInfoCircle} className={styles.infoButton} />,
            },
        ];

        const isEncompass = this.props.config.isIntegrationPlatformEncompass();

        const StyledTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} classes={{ popper: className }} />)(
            ({ theme }) => ({
                [`& .${tooltipClasses.tooltip}`]: {
                    minWidth: '350px',
                    backgroundColor: !isEncompass ? '#3c5063' : '#373e40',
                    color: '#ffffff',
                    boxShadow: theme.shadows[1],
                    textAlign: 'start',
                    padding: '10px',
                    marginLeft: '6px',
                    borderRadius: '6px',
                },
                [`& .${tooltipClasses.arrow}`]: {
                    color: !isEncompass ? '#3c5063' : '#373e40',
                    '&:before': {
                        boxShadow: theme.shadows[1],
                    },
                },
            }),
        );

        // Go Device columns added for GeoTab
        if (this.props.config.isIntegrationPlatformGeotab()) {
            rowHeaders.splice(2, 0, { id: 'goSerial', label: 'GO Serial #' });
            heartbeatRowHeaders.splice(2, 0, { id: 'goSerial', label: 'GO Serial #' });
        }

        // IsGpsTrackingEnabled for encompass
        if (this.props.config.isIntegrationPlatformEncompass()) {
            heartbeatRowHeaders.splice(3, 0, { id: 'isGpsTrackingEnabled', label: 'GPS Tracker' });
        }

        this.handleFilterGroups(deviceAssociations);

        return (
            <div>
                <div className={styles.main}>
                    <TermsOfUse />
                    <TypedSplitTreatments names={[splitTreatmentNames.videoEventHeaderVerbiage]} attributes={getSplitUserAttributes()}>
                        {({ treatments, isReady, isTimedout }: ISplitTreatmentsChildProps) => {
                            return (isReady || isTimedout) &&
                                isSplitTreatmentOn(treatments[splitTreatmentNames.videoEventHeaderVerbiage]) ? (
                                <PageHeader pageTitle={'Device Associations'} />
                            ) : (
                                <PageHeader_Split_Off pageTitle={'Device Associations'} />
                            );
                        }}
                    </TypedSplitTreatments>

                    {this.state.openSnackBar && (
                        <AlertMessage
                            message={this.state.alertMessage}
                            openSnackbar={this.state.openSnackBar}
                            alertType={this.state.alertType}
                            showIcon={isEncompass}
                            isUndo={true}
                            autoHide={true}
                            handleSnackClose={(): void => this.setState({ openSnackBar: false })}
                            handleSubmit={(): void => this.setState({ openSnackBar: false })}
                        />
                    )}
                    <div className={scssEnvironment == 'encompass' ? styles.filterBarEncompass : styles.filterBar}>
                        <div className={styles.vehicleFilter}>
                            <VPDropdown
                                isCombined={true}
                                name="Vehicles"
                                items={vehicles}
                                selectedValues={selectedVehiclesFilter}
                                onSelectedItemChange={this.handleVehicleFilterChange}
                            />
                        </div>
                        <div className={styles.vehicleFilter}>
                            <VPDropdown
                                isCombined={true}
                                name="Pairing Status"
                                items={pairingStatuses}
                                selectedValues={selectedPairingStatusFilter}
                                onSelectedItemChange={this.handlePairingStatusFilterChange}
                            />
                        </div>
                        <div className={styles.serialFilter}>
                            <TextField
                                label={this.props.config.isIntegrationPlatformGeotab() ? 'Search by serial #' : 'Camera Serial #'}
                                value={serialFilter}
                                onChange={this.handleSerialFilterChange}
                                style={{
                                    width: scssEnvironment == 'encompass' ? '250px' : '230px',
                                    margin: scssEnvironment == 'encompass' ? '0px 10px 0px 10px' : '',
                                }}
                                InputProps={{
                                    style: scssEnvironment === 'encompass' ? inputStyles : {},
                                    disableUnderline: scssEnvironment == 'encompass',
                                    endAdornment: (
                                        <Button
                                            onClick={this.handleClearSerialFilterClick}
                                            className={styles.closeButtonSerialFilter}
                                            hidden={serialFilter ? false : true}
                                        >
                                            <InputAdornment className={styles.inputAdormentSerialFilter} position={'end'}>
                                                <FontAwesomeIcon icon={faTimes} className={styles.closeIcon} />
                                            </InputAdornment>
                                        </Button>
                                    ),
                                }}
                                InputLabelProps={{ style: { marginLeft: scssEnvironment == 'encompass' ? 8 : 0 } }}
                            />
                        </div>
                        <div className={styles.buttonContainer}>
                            <DeviceAssociationExport csvTitle={this.state.csvTitle} filteredItems={this.state.filteredItems} />
                        </div>
                    </div>

                    {this.state.filteredItems.length === 0 && (
                        <div className={styles.noAssociations}>
                            {this.state.devicesLoaded ? (
                                <div>No Device Associations found for the criteria selected</div>
                            ) : (
                                <CircularProgress></CircularProgress>
                            )}
                        </div>
                    )}

                    {scssEnvironment === 'encompass' && this.state.filteredItems.length > 0 ? (
                        <div className={styles.scrollWindow} ref={(ref): any => (this.scrollParentRef2 = ref)}>
                            <InfiniteScroll
                                pageStart={this.state.currentPageNumber}
                                loadMore={this.getPage}
                                hasMore={this.state.pageItems.length < this.state.filteredItems.length}
                                loader={
                                    <div className="loader" key={0}>
                                        Loading ...
                                    </div>
                                }
                                useWindow={false}
                                getScrollParent={(): void => this.scrollParentRef2}
                            >
                                <Table className={styles.devicesTable}>
                                    <TableHead>
                                        <TableRow className={styles.headerRow}>
                                            <TypedSplitTreatments
                                                names={[splitTreatmentNames.streamaxHeartbeat]}
                                                attributes={getSplitUserAttributes()}
                                            >
                                                {({ treatments, isReady, isTimedout }: ISplitTreatmentsChildProps) => {
                                                    return isReady || isTimedout ? (
                                                        isSplitTreatmentOn(treatments[splitTreatmentNames.streamaxHeartbeat]) ? (
                                                            heartbeatRowHeaders.map((head) => {
                                                                return head.icon ? (
                                                                    <TableCell key={head.id} className={styles.headerCell}>
                                                                        <TableSortLabel
                                                                            key={head.id}
                                                                            className={styles.noSortingLabel}
                                                                            hideSortIcon={true}
                                                                        >
                                                                            {head.label}
                                                                            {head.icon ? (
                                                                                <ClickAwayListener onClickAway={this.handleTooltipClose}>
                                                                                    <StyledTooltip
                                                                                        PopperProps={{
                                                                                            disablePortal: true,
                                                                                        }}
                                                                                        onClose={this.handleTooltipClose}
                                                                                        open={this.state.isTooltipOpen}
                                                                                        disableFocusListener
                                                                                        disableHoverListener
                                                                                        disableTouchListener
                                                                                        placement="bottom-end"
                                                                                        arrow
                                                                                        title={
                                                                                            <div>
                                                                                                Connection Status indicates if there is no
                                                                                                power going to the camera; <b>Online</b> -
                                                                                                Vehicle is powered on, camera has power to
                                                                                                it, and has a network connection,{' '}
                                                                                                <b> Offline</b> - Vehicle may be powered
                                                                                                off; camera may not be plugged in; network
                                                                                                issues may be preventing the camera from
                                                                                                connection to the server.
                                                                                            </div>
                                                                                        }
                                                                                    >
                                                                                        <div
                                                                                            onClick={
                                                                                                !this.state.isTooltipOpen
                                                                                                    ? this.handleTooltipOpen
                                                                                                    : this.handleTooltipClose
                                                                                            }
                                                                                        >
                                                                                            {head.icon}
                                                                                        </div>
                                                                                    </StyledTooltip>
                                                                                </ClickAwayListener>
                                                                            ) : null}
                                                                        </TableSortLabel>
                                                                    </TableCell>
                                                                ) : (
                                                                    <TableCell
                                                                        key={head.id}
                                                                        className={styles.headerCell}
                                                                        sortDirection={
                                                                            this.state.sort === head.id ? this.state.sortOrder : false
                                                                        }
                                                                    >
                                                                        <TableSortLabel
                                                                            active={this.state.sort === head.id}
                                                                            direction={this.state.sortOrder}
                                                                            onClick={(): void => this.handleRequestSort(head.id)}
                                                                            className={styles.sortLabel}
                                                                        >
                                                                            {head.label}
                                                                        </TableSortLabel>
                                                                    </TableCell>
                                                                );
                                                            })
                                                        ) : (
                                                            rowHeaders.map((head) => {
                                                                return (
                                                                    <TableCell
                                                                        key={head.id}
                                                                        className={styles.headerCell}
                                                                        sortDirection={
                                                                            this.state.sort === head.id ? this.state.sortOrder : false
                                                                        }
                                                                    >
                                                                        <TableSortLabel
                                                                            active={this.state.sort === head.id}
                                                                            direction={this.state.sortOrder}
                                                                            onClick={(): void => this.handleRequestSort(head.id)}
                                                                            className={styles.sortLabel}
                                                                        >
                                                                            {head.label}
                                                                        </TableSortLabel>
                                                                    </TableCell>
                                                                );
                                                            })
                                                        )
                                                    ) : (
                                                        <></>
                                                    );
                                                }}
                                            </TypedSplitTreatments>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {pageItems.map((pairing: VehicleCameraPairingResponse, idx) => {
                                            const cameraDevice = pairing.cameraDevice || {};
                                            const cameraType = cameraDevice.deviceType || {};
                                            const vehicle = pairing.vehicle || {};
                                            const isPaired = cameraDevice.pairingStatus?.toLowerCase() === 'paired';
                                            return (
                                                <TableRow key={idx} className={styles.bodyRow}>
                                                    <TableCell>{cameraDevice.serialNumber}</TableCell>
                                                    <TableCell className={styles.bodyCell}>{cameraType.displayName}</TableCell>

                                                    <TableCell className={styles.bodyCell}>{vehicle.name}</TableCell>

                                                    <TypedSplitTreatments
                                                        names={[
                                                            splitTreatmentNames.gpsTracker,
                                                            splitTreatmentNames.modularUIDeviceAssociation,
                                                        ]}
                                                        attributes={getSplitUserAttributes()}
                                                    >
                                                        {({ treatments, isReady, isTimedout }: ISplitTreatmentsChildProps) => {
                                                            return isReady || isTimedout ? (
                                                                isSplitTreatmentsOnWithDefault(
                                                                    treatments[
                                                                        (splitTreatmentNames.gpsTracker,
                                                                        splitTreatmentNames.modularUIDeviceAssociation)
                                                                    ],
                                                                    false,
                                                                ) ? (
                                                                    <TableCell className={styles.bodyCell}>
                                                                        {pairing.isGpsTrackingEnabled ? 'On' : 'Off'}
                                                                    </TableCell>
                                                                ) : (
                                                                    <></>
                                                                )
                                                            ) : (
                                                                <></>
                                                            );
                                                        }}
                                                    </TypedSplitTreatments>

                                                    <TableCell className={styles.bodyCell}>
                                                        {pairing.startDate ? DateFormatter.date(moment(pairing.startDate)) : ''}
                                                    </TableCell>
                                                    <TypedSplitTreatments
                                                        names={[splitTreatmentNames.streamaxHeartbeat]}
                                                        attributes={getSplitUserAttributes()}
                                                    >
                                                        {({ treatments, isReady, isTimedout }: ISplitTreatmentsChildProps) => {
                                                            return isReady || isTimedout ? (
                                                                isSplitTreatmentOn(treatments[splitTreatmentNames.streamaxHeartbeat]) ? (
                                                                    <>
                                                                        <TableCell className={styles.bodyCell}>
                                                                            {cameraDevice.lastHeartbeatTime
                                                                                ? DateFormatter.dateAndTimezone(
                                                                                      moment(cameraDevice.lastHeartbeatTime),
                                                                                  )
                                                                                : ''}
                                                                        </TableCell>
                                                                        <TableCell className={styles.bodyCell}>
                                                                            {cameraDevice.lastProcessedEventTime
                                                                                ? DateFormatter.dateAndTimezone(
                                                                                      moment(cameraDevice.lastProcessedEventTime),
                                                                                  )
                                                                                : ''}
                                                                        </TableCell>
                                                                    </>
                                                                ) : (
                                                                    <></>
                                                                )
                                                            ) : (
                                                                <></>
                                                            );
                                                        }}
                                                    </TypedSplitTreatments>
                                                    <TableCell className={styles.bodyCell}>{cameraDevice.pairingStatus}</TableCell>

                                                    <TypedSplitTreatments
                                                        names={[splitTreatmentNames.streamaxHeartbeat]}
                                                        attributes={getSplitUserAttributes()}
                                                    >
                                                        {({ treatments, isReady, isTimedout }: ISplitTreatmentsChildProps) => {
                                                            return isReady || isTimedout ? (
                                                                isSplitTreatmentOn(treatments[splitTreatmentNames.streamaxHeartbeat]) ? (
                                                                    <>
                                                                        <TableCell
                                                                            className={`${styles.bodyCell} ${
                                                                                cameraDevice.onlineStatus === 'Online'
                                                                                    ? styles.onlineStreamaxStatus
                                                                                    : ''
                                                                            }`}
                                                                        >
                                                                            {cameraDevice.onlineStatus}
                                                                        </TableCell>
                                                                    </>
                                                                ) : (
                                                                    <></>
                                                                )
                                                            ) : (
                                                                <></>
                                                            );
                                                        }}
                                                    </TypedSplitTreatments>
                                                    <TableCell>
                                                        <div className={styles.encompassOptionColumn}>
                                                            <OptionsColumn
                                                                onDeletePairingClick={() => this.toggleDeletePairing(pairing.id)}
                                                                onLiveVideoClick={() => this.handleConfirmLiveVideo(true, pairing.id)}
                                                                onEditPairingClick={() =>
                                                                    this.toggleOpenPairing(
                                                                        pairing.id,
                                                                        isPaired ? undefined : cameraDevice.id,
                                                                    )
                                                                }
                                                                onRequestVideo={() => this.toggleOpenRequestVideo(pairing.id)}
                                                                onOpenTooltip={() => this.handleOpenTooltip(idx)}
                                                                isOpen={this.state.openTooltips[idx] || false}
                                                                isForPairing={!isPaired}
                                                            />
                                                        </div>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </InfiniteScroll>
                        </div>
                    ) : (
                        this.state.filteredItems.length > 0 && (
                            <Card className={styles.card}>
                                <div className={styles.scrollWindow} ref={(ref): any => (this.scrollParentRef2 = ref)}>
                                    <InfiniteScroll
                                        pageStart={this.state.currentPageNumber}
                                        loadMore={this.getPage}
                                        hasMore={this.state.pageItems.length < this.state.filteredItems.length}
                                        loader={
                                            <div className="loader" key={0}>
                                                Loading ...
                                            </div>
                                        }
                                        useWindow={false}
                                        getScrollParent={(): void => this.scrollParentRef2}
                                    >
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TypedSplitTreatments
                                                        names={[splitTreatmentNames.streamaxHeartbeat]}
                                                        attributes={getSplitUserAttributes()}
                                                    >
                                                        {({ treatments, isReady, isTimedout }: ISplitTreatmentsChildProps) => {
                                                            return isReady || isTimedout ? (
                                                                isSplitTreatmentOn(treatments[splitTreatmentNames.streamaxHeartbeat]) ? (
                                                                    heartbeatRowHeaders.map((head) => {
                                                                        return head.icon ? (
                                                                            <TableCell key={head.id} className={styles.headerCell}>
                                                                                <TableSortLabel
                                                                                    key={head.id}
                                                                                    className={styles.noSortingLabel}
                                                                                    hideSortIcon={true}
                                                                                >
                                                                                    {head.label}
                                                                                    {head.icon ? (
                                                                                        <ClickAwayListener
                                                                                            onClickAway={this.handleTooltipClose}
                                                                                        >
                                                                                            <div>
                                                                                                <StyledTooltip
                                                                                                    PopperProps={{
                                                                                                        disablePortal: true,
                                                                                                    }}
                                                                                                    onClose={this.handleTooltipClose}
                                                                                                    open={this.state.isTooltipOpen}
                                                                                                    disableFocusListener
                                                                                                    disableHoverListener
                                                                                                    disableTouchListener
                                                                                                    placement="bottom-end"
                                                                                                    arrow
                                                                                                    title={
                                                                                                        <div>
                                                                                                            Connection Status indicates if
                                                                                                            there is no power going to the
                                                                                                            camera; <b>Online</b> - Vehicle
                                                                                                            is powered on, camera has power
                                                                                                            to it, and has a network
                                                                                                            connection, <b> Offline</b> -
                                                                                                            Vehicle may be powered off;
                                                                                                            camera may not be plugged in;
                                                                                                            network issues may be preventing
                                                                                                            the camera from connection to
                                                                                                            the server.
                                                                                                        </div>
                                                                                                    }
                                                                                                >
                                                                                                    <div
                                                                                                        onClick={
                                                                                                            !this.state.isTooltipOpen
                                                                                                                ? this.handleTooltipOpen
                                                                                                                : this.handleTooltipClose
                                                                                                        }
                                                                                                    >
                                                                                                        {head.icon}
                                                                                                    </div>
                                                                                                </StyledTooltip>
                                                                                            </div>
                                                                                        </ClickAwayListener>
                                                                                    ) : null}
                                                                                </TableSortLabel>
                                                                            </TableCell>
                                                                        ) : (
                                                                            <TableCell
                                                                                key={head.id}
                                                                                className={styles.headerCell}
                                                                                sortDirection={
                                                                                    this.state.sort === head.id
                                                                                        ? this.state.sortOrder
                                                                                        : false
                                                                                }
                                                                            >
                                                                                <TableSortLabel
                                                                                    active={this.state.sort === head.id}
                                                                                    direction={this.state.sortOrder}
                                                                                    onClick={(): void => this.handleRequestSort(head.id)}
                                                                                    className={styles.sortLabel}
                                                                                >
                                                                                    {head.label}
                                                                                </TableSortLabel>
                                                                            </TableCell>
                                                                        );
                                                                    })
                                                                ) : (
                                                                    rowHeaders.map((head) => {
                                                                        return (
                                                                            <TableCell
                                                                                key={head.id}
                                                                                className={styles.headerCell}
                                                                                sortDirection={
                                                                                    this.state.sort === head.id
                                                                                        ? this.state.sortOrder
                                                                                        : false
                                                                                }
                                                                            >
                                                                                <TableSortLabel
                                                                                    active={this.state.sort === head.id}
                                                                                    direction={this.state.sortOrder}
                                                                                    onClick={(): void => this.handleRequestSort(head.id)}
                                                                                    className={styles.sortLabel}
                                                                                >
                                                                                    {head.label}
                                                                                </TableSortLabel>
                                                                            </TableCell>
                                                                        );
                                                                    })
                                                                )
                                                            ) : (
                                                                <></>
                                                            );
                                                        }}
                                                    </TypedSplitTreatments>
                                                    <TableCell />
                                                </TableRow>
                                            </TableHead>

                                            <TableBody>
                                                {pageItems.map((pairing: VehicleCameraPairingResponse, idx) => {
                                                    const cameraDevice = pairing.cameraDevice || {};
                                                    const cameraType = cameraDevice.deviceType || {};
                                                    const vehicle = pairing.vehicle || {};
                                                    const isPaired = cameraDevice.pairingStatus?.toLowerCase() === 'paired';
                                                    return (
                                                        <TableRow key={idx}>
                                                            <TableCell>{cameraDevice.serialNumber}</TableCell>
                                                            <TableCell>{cameraType.displayName}</TableCell>
                                                            {this.props.config.isIntegrationPlatformGeotab() && (
                                                                <TableCell className={styles.bodyCell}>
                                                                    {vehicle?.telematicDevice ? vehicle?.telematicDevice.serialNumber : ''}
                                                                </TableCell>
                                                            )}

                                                            <TableCell className={styles.bodyCell}>{vehicle.name}</TableCell>

                                                            <TableCell>
                                                                {pairing.startDate ? DateFormatter.date(moment(pairing.startDate)) : ''}
                                                            </TableCell>

                                                            <TypedSplitTreatments
                                                                names={[splitTreatmentNames.streamaxHeartbeat]}
                                                                attributes={getSplitUserAttributes()}
                                                            >
                                                                {({ treatments, isReady, isTimedout }: ISplitTreatmentsChildProps) => {
                                                                    return isReady || isTimedout ? (
                                                                        isSplitTreatmentOn(
                                                                            treatments[splitTreatmentNames.streamaxHeartbeat],
                                                                        ) ? (
                                                                            <>
                                                                                <TableCell className={styles.bodyCell}>
                                                                                    {cameraDevice.lastHeartbeatTime
                                                                                        ? DateFormatter.dateAndTimezone(
                                                                                              moment(cameraDevice.lastHeartbeatTime),
                                                                                          )
                                                                                        : ''}
                                                                                </TableCell>
                                                                                <TableCell className={styles.bodyCell}>
                                                                                    {cameraDevice.lastProcessedEventTime
                                                                                        ? DateFormatter.dateAndTimezone(
                                                                                              moment(cameraDevice.lastProcessedEventTime),
                                                                                          )
                                                                                        : ''}
                                                                                </TableCell>
                                                                            </>
                                                                        ) : (
                                                                            <></>
                                                                        )
                                                                    ) : (
                                                                        <></>
                                                                    );
                                                                }}
                                                            </TypedSplitTreatments>

                                                            <TableCell>{cameraDevice.pairingStatus}</TableCell>
                                                            <TypedSplitTreatments
                                                                names={[splitTreatmentNames.streamaxHeartbeat]}
                                                                attributes={getSplitUserAttributes()}
                                                            >
                                                                {({ treatments, isReady, isTimedout }: ISplitTreatmentsChildProps) => {
                                                                    return isReady || isTimedout ? (
                                                                        isSplitTreatmentOn(
                                                                            treatments[splitTreatmentNames.streamaxHeartbeat],
                                                                        ) ? (
                                                                            <>
                                                                                <TableCell
                                                                                    className={
                                                                                        cameraDevice.onlineStatus === 'Online'
                                                                                            ? styles.onlineStreamaxStatus
                                                                                            : ''
                                                                                    }
                                                                                >
                                                                                    {cameraDevice.onlineStatus}
                                                                                </TableCell>
                                                                            </>
                                                                        ) : (
                                                                            <></>
                                                                        )
                                                                    ) : (
                                                                        <></>
                                                                    );
                                                                }}
                                                            </TypedSplitTreatments>
                                                            <TableCell className={styles.optionsColumn}>
                                                                <OptionsColumn
                                                                    onDeletePairingClick={() => this.toggleDeletePairing(pairing.id)}
                                                                    onLiveVideoClick={() => this.handleConfirmLiveVideo(true, pairing.id)}
                                                                    onEditPairingClick={() =>
                                                                        this.toggleOpenPairing(
                                                                            pairing.id,
                                                                            isPaired ? undefined : cameraDevice?.id,
                                                                        )
                                                                    }
                                                                    onRequestVideo={() => this.toggleOpenRequestVideo(pairing.id)}
                                                                    onOpenTooltip={() => this.handleOpenTooltip(idx)}
                                                                    isOpen={this.state.openTooltips[idx] || false}
                                                                    isForPairing={!isPaired}
                                                                />
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                            </TableBody>
                                        </Table>
                                    </InfiniteScroll>
                                </div>
                            </Card>
                        )
                    )}
                </div>
                <TypedSplitTreatments names={[splitTreatmentNames.modularUIDeviceAssociation]} attributes={getSplitUserAttributes()}>
                    {({ treatments, isReady, isTimedout }: ISplitTreatmentsChildProps) => {
                        return isReady || isTimedout ? (
                            isSplitTreatmentOn(treatments[splitTreatmentNames.modularUIDeviceAssociation]) ? (
                                <PairingDialog
                                    open={this.state.showPairing}
                                    onClose={() => this.toggleOpenPairing()}
                                    vehicles={editableVehiclesForPairing}
                                    cameras={
                                        this.state.editParingId
                                            ? [deviceAssociations.find((da) => da.id === this.state.editParingId)?.cameraDevice]
                                            : cameras
                                    }
                                    handleVehicleChange={this.handleVehicleChange}
                                    device={this.state.deviceId}
                                    handleCameraChange={this.handleCameraChange}
                                    cameraId={this.state.cameraId}
                                    isEdit={this.state.editParingId !== undefined}
                                    handleAddPairing={this.handleAddPairing}
                                    config={this.props.config}
                                    handleGpsTrackerChange={this.handleGpsTrackerChange}
                                    isGpsTracker={this.state.gpsTrackerEnabled}
                                ></PairingDialog>
                            ) : (
                                <Dialog open={this.state.showPairing} maxWidth={'xs'} fullWidth={true} className={styles.dialog}>
                                    <ValidatorForm onSubmit={this.handleAddPairing}>
                                        <DialogTitle className={styles.pickerContent}>New Pairing</DialogTitle>
                                        <DialogContent className={styles.pickerContent}>
                                            <FormControl className={styles.select} required={true}>
                                                <Autocomplete
                                                    options={unpairedVehicles}
                                                    getOptionLabel={(option: any) =>
                                                        this.props.config.isIntegrationPlatformGeotab() ? option.serialNumber : option.name
                                                    }
                                                    style={{ paddingTop: 4 }}
                                                    onChange={this.handleVehicleChange}
                                                    value={unpairedVehicles.find((x) => x.id === this.state.device)}
                                                    clearIcon={<FontAwesomeIcon icon={faTimes} className={styles.clearIcon} />}
                                                    popupIcon={<FontAwesomeIcon icon={faAngleDown} className={styles.dropdownIcon} />}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label={
                                                                this.props.config.isIntegrationPlatformGeotab() ? 'Go Device' : 'Vehicle'
                                                            }
                                                            variant="outlined"
                                                            required
                                                        />
                                                    )}
                                                />
                                            </FormControl>
                                        </DialogContent>
                                        <DialogContent className={styles.pickerContent}>
                                            <FormControl className={styles.select} required={true}>
                                                <Autocomplete
                                                    options={cameras}
                                                    getOptionLabel={(option: any) => option.serialNumber}
                                                    style={{ paddingTop: 4 }}
                                                    onChange={this.handleCameraChange}
                                                    value={cameras.find((x) => x.id === this.state.cameraId)}
                                                    clearIcon={<FontAwesomeIcon icon={faTimes} className={styles.clearIcon} />}
                                                    popupIcon={<FontAwesomeIcon icon={faAngleDown} className={styles.dropdownIcon} />}
                                                    renderInput={(params) => (
                                                        <TextField {...params} label="Camera" variant="outlined" required />
                                                    )}
                                                />
                                            </FormControl>
                                        </DialogContent>
                                        <DialogActions>
                                            <Button onClick={() => this.toggleOpenPairing()}>Cancel</Button>
                                            <Button disabled={this.state.disableAddRemoveButton} type="submit">
                                                Save
                                            </Button>
                                        </DialogActions>
                                    </ValidatorForm>
                                </Dialog>
                            )
                        ) : (
                            <></>
                        );
                    }}
                </TypedSplitTreatments>
                <TypedSplitTreatments names={[splitTreatmentNames.videoRequestDeviceAssociation]} attributes={getSplitUserAttributes()}>
                    {({ treatments, isReady, isTimedout }: ISplitTreatmentsChildProps) => {
                        return isReady || isTimedout ? (
                            isSplitTreatmentOn(treatments[splitTreatmentNames.videoRequestDeviceAssociation]) ? (
                                <VideoRequestDialog
                                    open={this.state.showRequestVideo}
                                    onClose={() => this.toggleOpenRequestVideo()}
                                    config={this.props.config}
                                    device={this.state.device}
                                ></VideoRequestDialog>
                            ) : (
                                <></>
                            )
                        ) : (
                            <></>
                        );
                    }}
                </TypedSplitTreatments>

                <Dialog open={this.state.showDelete} className={styles.dialog}>
                    <DialogTitle>Remove Association?</DialogTitle>
                    <DialogActions>
                        <Button onClick={(): void => this.toggleDeletePairing(undefined)}>Cancel</Button>
                        <Button disabled={this.state.disableAddRemoveButton} onClick={this.handleDeletePairing}>
                            Remove
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog open={this.state.showLiveVideo} maxWidth={'lg'}>
                    <DialogContent className={styles.liveVideoDialog}>
                        <LiveVideo
                            vehicleCameraPairingId={this.state.vehicleCameraPairingSelected}
                            alertDriver={this.state.alertDriver}
                            onClose={() => this.handleShowLiveVideo(false, false)}
                        />
                    </DialogContent>
                </Dialog>

                {this.state.showConfirmLiveVideo && (
                    <ConfirmLiveVideo
                        isOpen={this.state.showConfirmLiveVideo}
                        handleShowLiveVideo={this.handleShowLiveVideo}
                        handleClose={this.handleConfirmLiveVideo}
                    />
                )}
            </div>
        );
    }
}

export const DeviceAssociation = ConfigService.inject(
    IntegrationPartnerDataService.inject(DeviceAssociationService.inject(_DeviceAssociation)),
);
